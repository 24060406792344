import {
  Box,
  Button,
  Divider,
  Flexbox,
  Heading,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Stack,
  StackRow,
  Textarea,
} from "codekit";

import { useForm, FormProvider } from "react-hook-form";
import { useState } from "react";

import {
  useCreateSolicupoMutation
} from "../../../../libs/redux/slices/Modimpxx/Solicupo/solicupoApiSlice";
import { setModuleURL } from "../../../../libs/redux/slices/authSlice/authSlice";
import { useDispatch } from "react-redux";

/**
 * Componente padre Antiprov Cliente
 * @returns Component
 */
const Solicupo = ({ doInfo, setIsSolicupo }) => {
  const dispatch = useDispatch();
  const methods = useForm({
    mode: "all"
  });

  const cimaModuleAutoriza = "Autoriza";
  const cimaModuleModimpxx = "Modimpxx";
  const AUTIDXXX = "101";
  const CIACRESX = "PENDIENTE";

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = methods;

  const [createSolicupo] = useCreateSolicupoMutation();
  const [isCreating, setIsCreating] = useState(false);

  const hasError = Object.keys(errors).length;
  const isFormValid = !hasError;

  //Guardado del formulario
  const onSubmit = async (formData) => {
    dispatch(setModuleURL(cimaModuleAutoriza.toLowerCase()));
    const newData = { ...formData };
    setIsCreating(true);
    await createSolicupo(newData)
      .unwrap()
      .then(async (response) => {
        setIsCreating(false);
        close();
      })
      .catch()
      .finally(() => {
        setIsCreating(false);
      });
  };

  const close = () => {
    dispatch(setModuleURL(cimaModuleModimpxx.toLowerCase()));
    setIsSolicupo(false);
  }

  return (
    <Modal size="sm" isOpen={true}>
      <ModalHeader>
        <Flexbox justifyContent="between" alignItems="center">
          <Heading variant="heading-6">Solicitar cupo adicional</Heading>
          <Button variant="secondary" icon="close" onlyIcon size="m" onClick={() => close()} />
        </Flexbox>
      </ModalHeader>
      <FormProvider {...methods}>
        <form id="solicupo" onSubmit={handleSubmit(onSubmit)}>
          <ModalBody size="sm">
            <Box mb={8}>
              <Divider text="Datos generales" color="accent-600" />
            </Box>
            <Box mb={8}>
              <Stack gap={3} columns={2}>
                <StackRow>
                  <Label>Id de la operación</Label>
                  <Input
                    {...register("DOCNROXX", { required: true })}
                    value={doInfo.DOCNROXX}
                    isInvalid={errors.DOCNROXX}
                    placeholder="DO"
                    readOnly={true}
                  />
                  <Input
                    style={{display:"none"}}
                    {...register("AUTIDXXX")}
                    type="hidden"
                    value={AUTIDXXX}></Input>
                  <input
                    {...register("CIACRESX")}
                    type="hidden"
                    value={CIACRESX}/>
                </StackRow>
                <StackRow>
                  <Label>Cliente</Label>
                  <Input
                    {...register("CLINOMXX", { required: true })}
                    value={doInfo.CLINOMXX}
                    isInvalid={errors.CLINOMXX}
                    placeholder="Cliente"
                    readOnly={true}
                  />
                  <Input
                    value={doInfo.CLIIDXXX}
                    {...register(`CLIIDXXX`, { required: true })}
                    type="hidden"></Input>
                </StackRow>
              </Stack>
              <Stack gap={3} columns={1}>
                <StackRow>
                  <Label>Valor autorización cupo</Label>
                  <Input
                    {...register(`AUTVLRXX`, { required: true })}
                    type="number"
                    min="0"
                    value={doInfo.AUTVLRXX}
                    onChange={(event) => {
                      setValue(`AUTVLRXX`, event.target.value)
                    }}
                    isInvalid={errors.AUTVLRXX}
                    placeholder="Valor autorización cupo"
                  />
                </StackRow>
              </Stack>
            </Box>
            <Stack gap={4}>
              <StackRow>
                <Label>Observaciones</Label>
                <Textarea {...register(`CIACOBSX`)} />
              </StackRow>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Flexbox justifyContent="between" alignItems="center" gap={4}>
              <Button
                type="button"
                variant="secondary"
                size="2l"
                minWidth={200}
                onClick={() => {
                  close();
                }}
              >
                Cancelar
              </Button>
              <Button
                type="submit"
                variant="primary-bold"
                size="2l"
                minWidth={200}
                isLoading={isCreating}
                disabled={!isFormValid}
              >
                Crear
              </Button>
            </Flexbox>
          </ModalFooter>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default Solicupo;
