import { Text, View } from "@react-pdf/renderer";
import { convertCurrencyNumber } from "../../utils/function";
import { styles } from "./stylesxx";

const Totalxxx = ({ data }) => {
  const { ANTTOTAL } = data;
  return (
    <View style={styles.containerTable}>
      <View style={[styles.headerRow, { backgroundColor: "#2875B4" }]}>
        <Text style={[styles.headerCell, { color: "white" }]}>TOTAL SOLICITUD ANTICIPO (COP)</Text>
        <Text style={[styles.headerCell, { color: "white" }]}>$ {convertCurrencyNumber(ANTTOTAL)}</Text>
      </View>
    </View>
  );
};

export default Totalxxx;
