import { Text, View } from "@react-pdf/renderer";
import { convertCurrencyNumber } from "../../utils/function";
import { styles } from "./stylesxx";

const Tablexxx = ({ data }) => {
  const { VALUEUSD, ANTTCIFX } = data;
  return (
    <View style={styles.containerTable}>
      <View style={styles.headerRow}>
        <Text style={styles.headerCell}>Valor en aduanas (USD)</Text>
      </View>
      {VALUEUSD.map((item, index) => {
        let color = "white";
        if (index % 2 !== 0) {
          color = "#EEF4F9";
        }
        return (
          <View key={`usd-${index}`} style={[styles.row, { backgroundColor: color }]}>
            <View style={styles.cell}>
              <Text style={styles.valueLabel}>Declaración</Text>
              <Text style={styles.value}>{item.LIMACEXX !== "" ? item.LIMACEXX : "\n"}</Text>
            </View>
            <View style={styles.cell}>
              <Text style={styles.valueLabel}>FOB</Text>
              <Text style={styles.value}>$ {convertCurrencyNumber(item.ANTFOBXX)}</Text>
            </View>
            <View style={styles.cell}>
              <Text style={styles.valueLabel}>Flete</Text>
              <Text style={styles.value}>$ {convertCurrencyNumber(item.ANTFLETE)}</Text>
            </View>
            <View style={styles.cell}>
              <Text style={styles.valueLabel}>Seguros</Text>
              <Text style={styles.value}>$ {convertCurrencyNumber(item.ANTSEGXX)}</Text>
            </View>
            <View style={styles.cell}>
              <Text style={styles.valueLabel}>Otros</Text>
              <Text style={styles.value}>$ {convertCurrencyNumber(item.ANTOTROS)}</Text>
            </View>
            <View style={styles.cell}>
              <Text style={styles.valueLabel}>Ajustes</Text>
              <Text style={styles.value}>$ {convertCurrencyNumber(item.ANTAJUST)}</Text>
            </View>
            <View style={styles.cell}>
              <Text style={styles.totalLabel}>Total</Text>
              <Text style={styles.totalValue}>$ {convertCurrencyNumber(item.ANTTOTAL)}</Text>
            </View>
          </View>
        );
      })}
      <View style={styles.totalRow}>
        <Text style={styles.totalText}>TOTAL VALOR CIF</Text>
        <Text style={[styles.totalText, { textAlign: "right" }]}>$ {convertCurrencyNumber(ANTTCIFX)}</Text>
      </View>
    </View>
  );
};

export default Tablexxx;
