/**
 * Funcion que retorna la informacion necesaria para la creacion de un paquete documental en Genio
 * @param string CLIIDXXX Nit del cliente
 * @param string DOCNUMXX Numero de documento
 * @returns object
 */
export const dataFile = (CLIIDXXX = "", DOCNUMXX = "") => {
  return {
    EMPNITXX: "800251957",
    AREAIDXX: "121", //Cliente
    SERIEIDX: "173", //Documentos cliente
    TIPOIDXX: "627", //Solicitud de Anticipo
    JSONMETX: {
      CLIIDXXX,
      DOCNUMXX,
    },
    NAMEXXXX: `anticipo_cliente_${DOCNUMXX}.pdf`,
    TYPEXXXX: "application/pdf",
  };
};
