import { useMemo} from "react";
import { useSelector } from "react-redux";
import { useGetClasificationFilterQuery } from "../../../libs/redux/slices/Graficsx/graficsxApiSlice";
import { getDataUser } from "../../../libs/redux/slices/authSlice/authSlice";
import { useSelect } from "../useSelect";

export function useClasificationsFilter({ MODIDXXX, PROIDXXX }) {
  const { USRIDXXX, CARIDXXX } = useSelector(getDataUser);
  const { data: clasifications } = useGetClasificationFilterQuery({
    CARIDXXX,
    USRIDXXX,
  });

  const { getDataForSelect } = useSelect();
  const newClasifications = useMemo(() => {
    if (!clasifications) return [];
    return getDataForSelect(clasifications.data, "CLATARID", "CLATARDE");
  }, [clasifications, getDataForSelect]);
  return [newClasifications];
}
