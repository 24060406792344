/**
 * retorna los valores por default para la grilla Valor en Aduanas USD
 */
export const valuesUsd = {
  ANTFOBXX: 0,
  ANTFLETE: 0,
  ANTSEGXX: 0,
  ANTOTROS: 0,
  ANTAJUST: 0,
  LIMACEXX: "",
  ANTTOTAL: 0,
};

/**
 * retorna los valores por default para la grilla Valor de los Tributos
 */
export const valuesCif = {
  ANTGRVMX: 0,
  ANTIVAXX: 0,
  ANTSALVX: 0,
  ANTCOMPX: 0,
  ANTDUMPX: 0,
  ANTSANCX: 0,
  ANTRESCT: 0,
  LIMACEXX: "",
  ANTTOTAL: 0,
};

/**
 * retorna los valores por default para el formulario de pagos a terceros
 */
export const valuesPAGOSTER = {
  CONIDXXX: "",
  ANTEVLRX: 0,
  CONDESXX: "",
};

/**
 * retorna los valores por default para el formulario de ingresos propios
 */
export const valuesINGREPRO = {
  CONCOBID: "",
  ANTPVLRX: 0,
  CONCOBDE: null,
};

/**
 * retorna los valores por default para el formulario de contactos
 */
export const valuesCONTACTX = {
  CONTACID: "",
  CONTACCO: "",
  CONTACNO: "",
  CONTACTE: "",
};

/**
 * Funcion para agregar formato numerico a los valores de los pagos a terceros
 */
export function setValuesVALUEUSD(data) {
  return data.map((item) => ({
    ...item,
    ANTFOBXX: parseFloat(item.ANTFOBXX),
    ANTFLETE: parseFloat(item.ANTFLETE),
    ANTSEGXX: parseFloat(item.ANTSEGXX),
    ANTOTROS: parseFloat(item.ANTOTROS),
    ANTAJUST: parseFloat(item.ANTAJUST),
  }));
}

/**
 * Funcion para agregar formato numerico a los valores de los tributos
 */
export function setValuesVALUETBT(data) {
  return data.map((item) => ({
    ...item,
    ANTGRVMX: parseFloat(item.ANTGRVMX),
    ANTIVAXX: parseFloat(item.ANTIVAXX),
    ANTSALVX: parseFloat(item.ANTSALVX),
    ANTCOMPX: parseFloat(item.ANTCOMPX),
    ANTDUMPX: parseFloat(item.ANTDUMPX),
    ANTSANCX: parseFloat(item.ANTSANCX),
    ANTRESCT: parseFloat(item.ANTRESCT),
  }));
}

/**
 * Funcion para agregar formato numerico a los valores de los terceros
 */
export function setValuesPAGOSTER(data) {
  return data.map((item) => ({
    ...item,
    ANTEVLRX: parseFloat(item.ANTEVLRX),
  }));
}

/**
 * Funcion para agregar formato numerico a los valores de los Ingresos Propios
 */
export function setValuesINGREPRO(data) {
  return data.map((item) => ({
    ...item,
    ANTPVLRX: parseFloat(item.ANTPVLRX),
  }));
}
