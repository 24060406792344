//Convertir un numero a formato moneda
export const convertCurrencyNumber = (number) => {
  return new Intl.NumberFormat("de-DE").format(number);
};

//Funcion para convertir blob a base64
export const blobToBase64 = (blob) => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise((resolve) => {
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });
};

//Funcion para convertir blob a File
export function blobToFile(blob, fileName) {
  blob.lastModifiedDate = new Date();
  blob.name = fileName;
  return blob;
}

//Funcion que retorna la fecha actual
export const getActualDate = () => {
  let dateActual = new Date();
  let year = dateActual.getFullYear();
  let month = (dateActual.getMonth() + 1).toString().padStart(2, "0");
  var day = dateActual.getDate().toString().padStart(2, "0");
  return `${year}-${month}-${day}`;
};
