import { Box, Button, Checkbox, Divider, Input, Label, Stack, StackRow, FieldError } from "codekit";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { sumDimCif } from "./utils/dataDimx";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { setMasiveValueTribute, setTotalTributes } from "../../../../libs/redux/slices/Modimpxx/Anticipo/anticipoSlice";

import { convertCurrencyNumber } from "../utils/function";
import { regexpRuleNumber } from "./utils/function";
import { valuesCif } from "./utils/defValue";

/**
 * Componente hijo para el pintado de la seccion valor de los tributos
 * @param object props propiedades que recibe el componente
 * @returns Component
 */
const Antribux = (props) => {
  const { dataDeclaration, isCreating, fields, append, remove, isVisualize } = props;
  const dispatch = useDispatch();
  const dataSum = sumDimCif(dataDeclaration);
  const { register, control, setValue, formState } = useFormContext();
  const { errors } = formState;
  const [total, setTotal] = useState(0);

  //Funcion para mostrar u ocultar valores por tributos
  const handleChangeCheck = (isChecked) => {
    if (isChecked) {
      const values = {
        ANTGRVMX: dataSum.LIMGRAXX,
        ANTIVAXX: dataSum.LIMSUBTX,
        ANTSALVX: dataSum.SUBSALTP,
        ANTCOMPX: dataSum.SUBDCOTP,
        ANTDUMPX: dataSum.SUBANTTP,
        ANTSANCX: dataSum.SUBSANTP,
        ANTRESCT: dataSum.SUBRESTP,
        LIMACEXX: "",
        ANTTOTAL: dataSum.ANTTOTAL,
      };
      dispatch(setMasiveValueTribute([values]));
      setTotal(dataSum.ANTTOTAL);
      for (const [key, value] of Object.entries(values)) {
        setValue(`VALUETBT.0.${key}`, value);
      }
    } else {
      remove();
      append(valuesCif, { shouldFocus: false });
      setTotal(0);
      setValue("ANTVDIMX", "NO");
      dispatch(setMasiveValueTribute([valuesCif]));
    }
  };

  //Funcion para mostrar u ocultar valores por declaracion
  const handleCheckDim = (isChecked) => {
    if (isChecked) {
      let values = [];
      if (dataDeclaration.length > 0) {
        remove();
        dataDeclaration.forEach((dim) => {
          let total =
            parseFloat(dim.LIMGRAXX) +
            parseFloat(dim.LIMSUBTX) +
            parseFloat(dim.SUBSALTP) +
            parseFloat(dim.SUBDCOTP) +
            parseFloat(dim.SUBANTTP) +
            parseFloat(dim.SUBSANTP) +
            parseFloat(dim.SUBRESTP);
          const fields = {
            ANTGRVMX: parseFloat(dim.LIMGRAXX),
            ANTIVAXX: parseFloat(dim.LIMSUBTX),
            ANTSALVX: parseFloat(dim.SUBSALTP),
            ANTCOMPX: parseFloat(dim.SUBDCOTP),
            ANTDUMPX: parseFloat(dim.SUBANTTP),
            ANTSANCX: parseFloat(dim.SUBSANTP),
            ANTRESCT: parseFloat(dim.SUBRESTP),
            LIMACEXX: dim.LIMACEXX,
            ANTTOTAL: total,
            SUBIDXXX: dim.SUBIDXXX,
          };
          values.push(fields);
          append(fields, { shouldFocus: false });
        });
        dispatch(setMasiveValueTribute(values));
        setTotal(dataSum.ANTTOTAL);
      }
    } else {
      remove();
      handleChangeCheck(true);
    }
  };

  const VALUETBT = useWatch({ name: "VALUETBT", control });

  //Funcion para sumar valores
  const handleSetTotal = (element) => {
    return (
      parseFloat(element.ANTGRVMX) +
      parseFloat(element.ANTIVAXX) +
      parseFloat(element.ANTSALVX) +
      parseFloat(element.ANTCOMPX) +
      parseFloat(element.ANTDUMPX) +
      parseFloat(element.ANTSANCX) +
      parseFloat(element.ANTRESCT)
    );
  };

  //Hook para cambiar el valor total
  useEffect(() => {
    if (VALUETBT) {
      let total = 0;
      VALUETBT.forEach((element) => {
        const ANTTOTAL = handleSetTotal(element);
        total += ANTTOTAL;
      });
      setTotal(total);
      setValue("ANTTRIBU", total);
      dispatch(setTotalTributes(total));
    }
  }, [VALUETBT, dispatch, setValue]);

  //Funcion para remover grilla
  const handleRemove = (index) => {
    remove(index);
    const copyObject = [...VALUETBT];
    copyObject.splice(index, 1);
    dispatch(setMasiveValueTribute(copyObject));
  };

  //Funcion para agregar grilla
  const handleAppend = () => {
    append(valuesCif, { shouldFocus: false });
    const copyObject = [...VALUETBT];
    copyObject.push(valuesCif);
    dispatch(setMasiveValueTribute(copyObject));
  };

  //Creo componente controlado, Agente de Carga
  const isTribute = useWatch({ name: "ANTTDIMX", control });
  const isDim = useWatch({ name: "ANTVDIMX", control });

  return (
    <>
      <Box mb={8}>
        <Divider text="Valor de los Tributos" color="accent-600" />
      </Box>
      {dataDeclaration.length > 0 && (
        <Box mb={8}>
          <Box rounded="md" border={1} spacing={2}>
            <Stack columnsVariant="form-2" gap={2}>
              <StackRow>
                <Controller
                  control={control}
                  name="ANTTDIMX"
                  render={({ field: { onChange } }) => (
                    <Checkbox
                      checked={isTribute === "SI"}
                      onChange={(event) => {
                        onChange(event.target.checked ? "SI" : "NO");
                        handleChangeCheck(event.target.checked);
                      }}
                      readOnly={isCreating || isVisualize}
                    >
                      Traer valores tributos de la Declaración
                    </Checkbox>
                  )}
                />
              </StackRow>
              {isTribute === "SI" && (
                <StackRow>
                  <Controller
                    control={control}
                    name="ANTVDIMX"
                    render={({ field: { onChange } }) => (
                      <Checkbox
                        checked={isDim === "SI"}
                        onChange={(event) => {
                          onChange(event.target.checked ? "SI" : "NO");
                          handleCheckDim(event.target.checked);
                        }}
                        readOnly={isCreating || isVisualize}
                      >
                        Valores por Declaración
                      </Checkbox>
                    )}
                  />
                </StackRow>
              )}
            </Stack>
          </Box>
        </Box>
      )}
      <Box mb={8}>
        <Label>Valores</Label>
        <Box bg="neutral-200" border={1} borderColor="neutral-300" spacing={3} rounded="md">
          <Stack gap={2}>
            {fields.map((item, index) => {
              let total = 0;
              if (VALUETBT[index]) {
                total = handleSetTotal(VALUETBT[index]);
              }
              return (
                <Box key={item.id} spacingX={2} spacingTop={2} spacingBottom={4} rounded="md" bg="white">
                  <Stack justifyItems="end">
                    <Button
                      type="button"
                      onClick={() => handleRemove(index)}
                      variant="error-light"
                      icon="trash"
                      disabled={fields.length === 1 || isVisualize}
                      size="m"
                      onlyIcon
                      inactive={isCreating}
                    />
                  </Stack>
                  <Stack gap={4} columnsVariant="form-4">
                    {isDim === "SI" && (
                      <StackRow>
                        <Label>No.</Label>
                        <Input
                          type="number"
                          {...register(`VALUETBT.${index}.SUBIDXXX`)}
                          defaultValue={item.SUBIDXXX}
                          readOnly={true}
                        />
                      </StackRow>
                    )}
                    <StackRow>
                      <Label>Gravamen</Label>
                      <Input
                        type="number"
                        {...register(`VALUETBT.${index}.ANTGRVMX`, { pattern: regexpRuleNumber() })}
                        isInvalid={errors.VALUETBT && errors.VALUETBT[index] && errors.VALUETBT[index].ANTGRVMX}
                        readOnly={isCreating || isVisualize}
                      />
                      {errors.VALUETBT && errors.VALUETBT[index] && errors.VALUETBT[index].ANTGRVMX && (
                        <FieldError>Debe contener maximo 18 enteros y 5 decimales</FieldError>
                      )}
                    </StackRow>
                    <StackRow>
                      <Label>Iva</Label>
                      <Input
                        type="number"
                        {...register(`VALUETBT.${index}.ANTIVAXX`, { pattern: regexpRuleNumber() })}
                        isInvalid={errors.VALUETBT && errors.VALUETBT[index] && errors.VALUETBT[index].ANTIVAXX}
                        readOnly={isCreating || isVisualize}
                      />
                      {errors.VALUETBT && errors.VALUETBT[index] && errors.VALUETBT[index].ANTIVAXX && (
                        <FieldError>Debe contener maximo 18 enteros y 5 decimales</FieldError>
                      )}
                    </StackRow>
                    <StackRow>
                      <Label>Salvaguardia</Label>
                      <Input
                        type="number"
                        {...register(`VALUETBT.${index}.ANTSALVX`, { pattern: regexpRuleNumber() })}
                        isInvalid={errors.VALUETBT && errors.VALUETBT[index] && errors.VALUETBT[index].ANTSALVX}
                        readOnly={isCreating || isVisualize}
                      />
                      {errors.VALUETBT && errors.VALUETBT[index] && errors.VALUETBT[index].ANTSALVX && (
                        <FieldError>Debe contener maximo 18 enteros y 5 decimales</FieldError>
                      )}
                    </StackRow>
                    <StackRow>
                      <Label>Compensatorio</Label>
                      <Input
                        type="number"
                        {...register(`VALUETBT.${index}.ANTCOMPX`, { pattern: regexpRuleNumber() })}
                        isInvalid={errors.VALUETBT && errors.VALUETBT[index] && errors.VALUETBT[index].ANTCOMPX}
                        readOnly={isCreating || isVisualize}
                      />
                      {errors.VALUETBT && errors.VALUETBT[index] && errors.VALUETBT[index].ANTCOMPX && (
                        <FieldError>Debe contener maximo 18 enteros y 5 decimales</FieldError>
                      )}
                    </StackRow>
                    <StackRow>
                      <Label>Antidumping</Label>
                      <Input
                        type="number"
                        {...register(`VALUETBT.${index}.ANTDUMPX`, { pattern: regexpRuleNumber() })}
                        isInvalid={errors.VALUETBT && errors.VALUETBT[index] && errors.VALUETBT[index].ANTDUMPX}
                        readOnly={isCreating || isVisualize}
                      />
                      {errors.VALUETBT && errors.VALUETBT[index] && errors.VALUETBT[index].ANTDUMPX && (
                        <FieldError>Debe contener maximo 18 enteros y 5 decimales</FieldError>
                      )}
                    </StackRow>
                    <StackRow>
                      <Label>Sanción</Label>
                      <Input
                        type="number"
                        {...register(`VALUETBT.${index}.ANTSANCX`, { pattern: regexpRuleNumber() })}
                        isInvalid={errors.VALUETBT && errors.VALUETBT[index] && errors.VALUETBT[index].ANTSANCX}
                        readOnly={isCreating || isVisualize}
                      />
                      {errors.VALUETBT && errors.VALUETBT[index] && errors.VALUETBT[index].ANTSANCX && (
                        <FieldError>Debe contener maximo 18 enteros y 5 decimales</FieldError>
                      )}
                    </StackRow>
                    <StackRow>
                      <Label>Rescate</Label>
                      <Input
                        type="number"
                        {...register(`VALUETBT.${index}.ANTRESCT`, { pattern: regexpRuleNumber() })}
                        isInvalid={errors.VALUETBT && errors.VALUETBT[index] && errors.VALUETBT[index].ANTRESCT}
                        readOnly={isCreating || isVisualize}
                      />
                      {errors.VALUETBT && errors.VALUETBT[index] && errors.VALUETBT[index].ANTRESCT && (
                        <FieldError>Debe contener maximo 18 enteros y 5 decimales</FieldError>
                      )}
                    </StackRow>
                    <StackRow>
                      <Label>N. Declaración</Label>
                      <Input {...register(`VALUETBT.${index}.LIMACEXX`)} readOnly={isCreating || isVisualize} />
                    </StackRow>
                    <StackRow>
                      <Label>Total</Label>
                      <Input {...register(`VALUETBT.${index}.ANTTOTAL`)} value={total} readOnly />
                    </StackRow>
                  </Stack>
                </Box>
              );
            })}
            <StackRow>
              <Button
                type="button"
                variant="secondary-light"
                icon="plus"
                disabled={isCreating || isVisualize}
                size="l"
                onClick={() => handleAppend()}
                inactive={isCreating}
              >
                Agregar otro
              </Button>
            </StackRow>
          </Stack>
        </Box>
      </Box>
      <Box mb={8} bg="success-100" rounded="md" spacing={2} border={1} borderColor="success-300">
        <Stack columnsVariant="form-2">
          <StackRow>
            <Label weight="medium">Total de Tributos</Label>
          </StackRow>
          <StackRow>
            <Label alignment="right">$ {convertCurrencyNumber(total)}</Label>
            <input type="hidden" {...register("ANTTRIBU")} value={total} />
          </StackRow>
        </Stack>
      </Box>
    </>
  );
};

export default Antribux;
