import { Box, Button, Checkbox, Divider, FieldError, Input, Label, Stack, StackRow } from "codekit";
import { useEffect, useState } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { sumDimUsd } from "./utils/dataDimx";
import { useDispatch } from "react-redux";
import { setMasiveValueUsd, setTotalUsd } from "../../../../libs/redux/slices/Modimpxx/Anticipo/anticipoSlice";
import { convertCurrencyNumber } from "../utils/function";
import { regexpRuleNumber } from "./utils/function";
import { valuesUsd } from "./utils/defValue";

/**
 * Componente hijo para el pintado de la seccion valor en aduana USD
 * @param object props propiedades que recibe el componente
 * @returns Component
 */
const Aduanusd = (props) => {
  const { dataDeclaration, isCreating, fields, append, remove, isVisualize } = props;
  const dispatch = useDispatch();
  const dataSum = sumDimUsd(dataDeclaration);
  const { register, control, setValue, formState } = useFormContext();
  const { errors } = formState;
  const [total, setTotal] = useState(0);

  //Funcion para mostrar u ocultar valores por tributos
  const handleChangeCheck = (isChecked) => {
    if (isChecked) {
      const values = {
        ANTFOBXX: dataSum.LIMVLRXX,
        ANTFLETE: dataSum.LIMFLEXX,
        ANTSEGXX: dataSum.LIMSEGXX,
        ANTOTROS: dataSum.LIMOTRXX,
        ANTAJUST: dataSum.LIMAJUXX,
        LIMACEXX: "",
        ANTTOTAL: dataSum.ANTTOTAL,
      };
      dispatch(setMasiveValueUsd([values]));
      setTotal(dataSum.ANTTOTAL);
      for (const [key, value] of Object.entries(values)) {
        setValue(`VALUEUSD.0.${key}`, value);
      }
    } else {
      remove();
      append(valuesUsd, { shouldFocus: false });
      setTotal(0);
      setValue("ANTVLDIX", "NO");
      dispatch(setMasiveValueUsd([valuesUsd]));
    }
  };

  //Funcion para mostrar u ocultar valores por declaracion
  const handleCheckDim = (isChecked) => {
    if (isChecked) {
      let values = [];
      if (dataDeclaration.length > 0) {
        remove();
        dataDeclaration.forEach((dim) => {
          let total =
            parseFloat(dim.LIMVLRXX) +
            parseFloat(dim.LIMFLEXX) +
            parseFloat(dim.LIMSEGXX) +
            parseFloat(dim.LIMOTRXX) +
            parseFloat(dim.LIMAJUXX);
          const fields = {
            ANTFOBXX: parseFloat(dim.LIMVLRXX),
            ANTFLETE: parseFloat(dim.LIMFLEXX),
            ANTSEGXX: parseFloat(dim.LIMSEGXX),
            ANTOTROS: parseFloat(dim.LIMOTRXX),
            ANTAJUST: parseFloat(dim.LIMAJUXX),
            LIMACEXX: dim.LIMACEXX,
            ANTTOTAL: total,
            SUBIDXXX: dim.SUBIDXXX,
          };
          values.push(fields);
          append(fields, { shouldFocus: false });
        });
        dispatch(setMasiveValueUsd(values));
        setTotal(dataSum.ANTTOTAL);
      }
    } else {
      remove();
      handleChangeCheck(true);
    }
  };

  const VALUEUSD = useWatch({ name: "VALUEUSD", control });

  //Funcion para sumar valores
  const handleSetTotal = (element) => {
    return (
      parseFloat(element.ANTFOBXX) +
      parseFloat(element.ANTFLETE) +
      parseFloat(element.ANTSEGXX) +
      parseFloat(element.ANTOTROS) +
      parseFloat(element.ANTAJUST)
    );
  };

  //Hook para cambiar el valor total
  useEffect(() => {
    if (VALUEUSD) {
      let total = 0;
      VALUEUSD.forEach((element) => {
        let ANTTOTAL = handleSetTotal(element);
        total += parseFloat(ANTTOTAL);
      });
      setTotal(total);
      setValue("ANTTCIFX", total);
      dispatch(setTotalUsd(total));
    }
  }, [VALUEUSD, dispatch, setValue]);

  //Funcion para remover grilla
  const handleRemove = (index) => {
    remove(index);
    const copyObject = [...VALUEUSD];
    copyObject.splice(index, 1);
    dispatch(setMasiveValueUsd(copyObject));
  };

  //Funcion para agregar grilla
  const handleAppend = () => {
    append(valuesUsd, { shouldFocus: false });
    const copyObject = [...VALUEUSD];
    copyObject.push(valuesUsd);
    dispatch(setMasiveValueUsd(copyObject));
  };

  //Obtener el valor de los check en el cambio de valor
  const isTribute = useWatch({ name: "ANTTBDIX", control });
  const isDim = useWatch({ name: "ANTVLDIX", control });
  const ANTTCIFX = useWatch({ name: "ANTTCIFX", control });
  return (
    <>
      <Box mb={8}>
        <Divider text="Valor en Aduanas USD" color="accent-600" />
      </Box>
      {dataDeclaration.length > 0 && (
        <Box mb={8}>
          <Box rounded="md" border={1} spacing={2}>
            <Stack columnsVariant="form-2" gap={2}>
              <StackRow>
                <Controller
                  control={control}
                  name="ANTTBDIX"
                  render={({ field: { onChange } }) => (
                    <Checkbox
                      checked={isTribute === "SI"}
                      onChange={(event) => {
                        onChange(event.target.checked ? "SI" : "NO");
                        handleChangeCheck(event.target.checked);
                      }}
                      readOnly={isCreating || isVisualize}
                    >
                      Traer valores tributos de la Declaración
                    </Checkbox>
                  )}
                />
              </StackRow>
              {isTribute === "SI" && (
                <StackRow>
                  <Controller
                    control={control}
                    name="ANTVLDIX"
                    render={({ field: { onChange } }) => (
                      <Checkbox
                        checked={isDim === "SI"}
                        onChange={(event) => {
                          onChange(event.target.checked ? "SI" : "NO");
                          handleCheckDim(event.target.checked);
                        }}
                        readOnly={isCreating || isVisualize}
                      >
                        Valores por Declaración
                      </Checkbox>
                    )}
                  />
                </StackRow>
              )}
            </Stack>
          </Box>
        </Box>
      )}
      <Box mb={8}>
        <Label>Valores</Label>
        <Box bg="neutral-200" border={1} borderColor="neutral-300" spacing={3} rounded="md">
          <Stack gap={2}>
            {fields.map((item, index) => {
              let total = 0;
              if (VALUEUSD[index]) {
                total = handleSetTotal(VALUEUSD[index]);
              }
              return (
                <Box key={item.id} spacingX={2} spacingTop={2} spacingBottom={4} rounded="md" bg="white">
                  <Stack justifyItems="end">
                    <Button
                      type="button"
                      onClick={() => handleRemove(index)}
                      variant="error-light"
                      icon="trash"
                      disabled={fields.length === 1 || isVisualize}
                      size="m"
                      onlyIcon
                      inactive={isCreating}
                    />
                  </Stack>
                  <Stack gap={4} columnsVariant="form-4">
                    {isDim === "SI" && (
                      <StackRow>
                        <Label>No.</Label>
                        <Input
                          type="number"
                          {...register(`VALUEUSD.${index}.SUBIDXXX`)}
                          defaultValue={item.SUBIDXXX}
                          readOnly={true}
                        />
                      </StackRow>
                    )}
                    <StackRow>
                      <Label>FOB</Label>
                      <Input
                        type="number"
                        {...register(`VALUEUSD.${index}.ANTFOBXX`, { pattern: regexpRuleNumber() })}
                        isInvalid={errors.VALUEUSD && errors.VALUEUSD[index] && errors.VALUEUSD[index].ANTFOBXX}
                        readOnly={isCreating || isVisualize}
                      />
                      {errors.VALUEUSD && errors.VALUEUSD[index] && errors.VALUEUSD[index].ANTFOBXX && (
                        <FieldError>Debe contener maximo 18 enteros y 5 decimales</FieldError>
                      )}
                    </StackRow>
                    <StackRow>
                      <Label>Flete</Label>
                      <Input
                        type="number"
                        {...register(`VALUEUSD.${index}.ANTFLETE`, { pattern: regexpRuleNumber() })}
                        isInvalid={errors.VALUEUSD && errors.VALUEUSD[index] && errors.VALUEUSD[index].ANTFLETE}
                        readOnly={isCreating || isVisualize}
                      />
                      {errors.VALUEUSD && errors.VALUEUSD[index] && errors.VALUEUSD[index].ANTFLETE && (
                        <FieldError>Debe contener maximo 18 enteros y 5 decimales</FieldError>
                      )}
                    </StackRow>
                    <StackRow>
                      <Label>Seguro</Label>
                      <Input
                        type="number"
                        {...register(`VALUEUSD.${index}.ANTSEGXX`, { pattern: regexpRuleNumber() })}
                        isInvalid={errors.VALUEUSD && errors.VALUEUSD[index] && errors.VALUEUSD[index].ANTSEGXX}
                        readOnly={isCreating || isVisualize}
                      />
                      {errors.VALUEUSD && errors.VALUEUSD[index] && errors.VALUEUSD[index].ANTSEGXX && (
                        <FieldError>Debe contener maximo 18 enteros y 5 decimales</FieldError>
                      )}
                    </StackRow>
                    <StackRow>
                      <Label>Otros Gastos</Label>
                      <Input
                        type="number"
                        {...register(`VALUEUSD.${index}.ANTOTROS`, { pattern: regexpRuleNumber() })}
                        isInvalid={errors.VALUEUSD && errors.VALUEUSD[index] && errors.VALUEUSD[index].ANTOTROS}
                        readOnly={isCreating || isVisualize}
                      />
                      {errors.VALUEUSD && errors.VALUEUSD[index] && errors.VALUEUSD[index].ANTOTROS && (
                        <FieldError>Debe contener maximo 18 enteros y 5 decimales</FieldError>
                      )}
                    </StackRow>
                    <StackRow>
                      <Label>Ajustes</Label>
                      <Input
                        type="number"
                        {...register(`VALUEUSD.${index}.ANTAJUST`, { pattern: regexpRuleNumber() })}
                        isInvalid={errors.VALUEUSD && errors.VALUEUSD[index] && errors.VALUEUSD[index].ANTAJUST}
                        readOnly={isCreating || isVisualize}
                      />
                      {errors.VALUEUSD && errors.VALUEUSD[index] && errors.VALUEUSD[index].ANTAJUST && (
                        <FieldError>Debe contener maximo 18 enteros y 5 decimales</FieldError>
                      )}
                    </StackRow>
                    <StackRow>
                      <Label>N. Declaración</Label>
                      <Input {...register(`VALUEUSD.${index}.LIMACEXX`)} readOnly={isCreating || isVisualize} />
                    </StackRow>
                    <StackRow>
                      <Label>Total</Label>
                      <Input {...register(`VALUEUSD.${index}.ANTTOTAL`)} value={total} readOnly />
                    </StackRow>
                  </Stack>
                </Box>
              );
            })}
            <StackRow>
              <Button
                type="button"
                variant="secondary-light"
                icon="plus"
                disabled={isCreating || isVisualize}
                size="l"
                onClick={() => handleAppend()}
                inactive={isCreating}
              >
                Agregar otro
              </Button>
            </StackRow>
          </Stack>
        </Box>
      </Box>
      <Box mb={8} bg="success-100" rounded="md" spacing={2} border={1} borderColor="success-300">
        <Stack columnsVariant="form-2">
          <StackRow>
            <Label weight="medium">Total valor CIF</Label>
          </StackRow>
          <StackRow>
            <Label alignment="right">$ {convertCurrencyNumber(total > 0 ? total : ANTTCIFX)}</Label>
            <input type="hidden" {...register("ANTTCIFX")} value={total > 0 ? total : ANTTCIFX} />
          </StackRow>
        </Stack>
      </Box>
    </>
  );
};

export default Aduanusd;
