import { apiSlice } from "../apiSlice";
export const autocompApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAutocomplete: builder.mutation({
      query: (parameters) => ({
        url: `/${parameters.MODURLXX}/${parameters.PRODURLX}/${parameters.endpoint}/${parameters.filter}`,
        method: "GET",
        headers: { Modulecima: parameters.MODURLXX.toLowerCase() },
      }),
    }),
    getAutocompleteWithParams: builder.mutation({
      query: (parameters) => ({
        url: `/${parameters.MODURLXX}/${parameters.PRODURLX}/${parameters.endpoint}`,
        method: "GET",
        params: { filter: parameters.filter },
        headers: { Modulecima: parameters.MODURLXX.toLowerCase() },
      }),
    }),
  }),
});

export const { useGetAutocompleteMutation, useGetAutocompleteWithParamsMutation } = autocompApiSlice;
