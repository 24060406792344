/**
 * Hook para el cargue del pdf a genio y envio de correo
 * @author Oscar Rosero
 * @since 2023-12-27
 */

import { pdf } from "@react-pdf/renderer";

import { useSelector } from "react-redux";

import { getDataUser } from "../../../../../libs/redux/slices/authSlice/authSlice";
import {
  useConfirmPackagePresignedMutation,
  useCreatePackagePresignedMutation,
} from "../../../../../libs/redux/slices/Genioxxx/genioxxxApiSlice";
import {
  useNotificationGenioMutation,
  useSendEmailAdvanceMutation,
} from "../../../../../libs/redux/slices/Modimpxx/Anticipo/anticipoApiSlice";

import Pagemain from "../pdf/Pagemain";
import { dataFile } from "./dataFile";
import { blobToFile } from "../../../../../utils/globalFunctions";
import { shootToast } from "codekit";

export const useSendPDF = () => {
  const infoUser = useSelector(getDataUser);
  const [createPackagePresigned] = useCreatePackagePresignedMutation();
  const [confirmPackagePresigned] = useConfirmPackagePresignedMutation();
  const [sendEmailAdvance] = useSendEmailAdvanceMutation();
  const [notificationGenio] = useNotificationGenioMutation();

  //Crear prefirma pdf en aws
  const handleGeneratePdf = async (consecutive, dataPDF, dataClient) => {
    //Genero el pdf
    const pdfBlob = await pdf(<Pagemain infoUser={infoUser} dataPDF={dataPDF} dataClient={dataClient} />).toBlob();
    const data = dataFile(dataClient.id, consecutive);
    await handlecreatePackage(data, dataPDF, consecutive, blobToFile(pdfBlob, "anticipo_cliente.pdf"));
  };

  //Creo la prefirma del archivo en aws
  const handlecreatePackage = async (dataFile, dataPDF, consecutive, file) => {
    await createPackagePresigned(dataFile)
      .then(async (response) => {
        let newRow = {};
        newRow.URLPRESI = response.data.data.url;
        newRow.IDARCHXX = response.data.data.IDARCHXX;
        await uploadPresignedFile(newRow, dataPDF, consecutive, file);
      })
      .catch(() => {
        //Genero notificacion de error
        handleNotificationGenio(dataPDF, consecutive);
      });
  };
  //Subir archivo al bucket s3
  const uploadPresignedFile = async (data, dataPDF, consecutive, file) => {
    var requestOptions = {
      method: "PUT",
      body: file,
      redirect: "follow",
    };

    fetch(data.URLPRESI, requestOptions)
      .then(async () => {
        await confirmUploadPresigned(data.IDARCHXX, dataPDF, consecutive);
      })
      .catch();
  };

  const confirmUploadPresigned = async (nIDARCHXX, dataPDF, consecutive) => {
    await confirmPackagePresigned({ path: { IDARCHXX: nIDARCHXX }, body: { REGESTXX: "ACTIVO" } })
      .then(async (response) => {
        const data = { ...dataPDF };
        data.IDARCHXX = nIDARCHXX;
        data.ANTCIDXX = consecutive;
        data.DOCDURLX = response.data.DOCDURLX;
        await sendEmailAdvance(data)
          .unwrap()
          .then(() => {
            shootToast({
              variant: "success",
              text: "La información se ha enviado con Exito!",
              duration: 5000,
              autoHide: true,
            });
          })
          .catch();
      })
      .catch();
  };

  //Funcion para la generacion de la notificacion de error en genio
  const handleNotificationGenio = (dataPDF, consecutive) => {
    //Genero notificacion de error
    const data = { ...dataPDF, ANTCIDXX: consecutive };
    notificationGenio(data);
  };
  return { handleGeneratePdf, sendEmailAdvance };
};
