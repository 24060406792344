import { StyleSheet } from "@react-pdf/renderer";

export const styles = StyleSheet.create({
  table: {
    backgroundColor: "#EEF4F9",
    display: "table",
    width: "100%",
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  header: {
    alignSelf: "stretch",
    paddingBottom: 10,
    paddingTop: 30,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  container: {
    paddingLeft: 48,
    paddingRight: 48,
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    flexGrow: 1,
  },
  headerContainer: {
    width: 500,
    height: "5%",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 30,
    borderBottom: "1px solid #E6E6E6",
    paddingBottom: 30,
  },
  infoItem: {
    color: "#292929",
    fontSize: 10,
    fontFamily: "Helvetica",
    wordWrap: "break-word",
  },
  containerHeader: {
    width: "40%",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 16,
    padding: 25,
  },
  textHeader: {
    alignSelf: "stretch",
    textAlign: "right",
    color: "#292929",
    fontSize: 10,
    fontFamily: "Helvetica",
    paddingBottom: 5,
  },
  title: {
    color: "black",
    fontSize: 15,
    fontFamily: "Helvetica",
    fontWeight: "bold",
    marginBottom: 12,
  },
  date: {
    color: "#292929",
    fontSize: 13,
    fontFamily: "Helvetica",
  },
  boxContainer: {
    width: 500,
    height: "auto",
    padding: 16,
    borderRadius: 8,
    border: "1px solid #E6E6E6",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    display: "inline-flex",
    gap: 30,
  },
  box: {
    alignSelf: "stretch",
    justifyContent: "space-between",
    alignItems: "flex-start",
    display: "inline-flex",
  },
  infoClient: {
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    display: "inline-flex",
  },
  titleClient: {
    color: "#292929",
    fontSize: 13,
    fontFamily: "Helvetica",
    fontWeight: "700",
    wordWrap: "break-word",
  },
  dataClient: {
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    display: "flex",
  },
  textClient: {
    color: "#292929",
    fontSize: 10,
    fontFamily: "Helvetica",
    fontWeight: "400",
    wordWrap: "break-word",
  },
  containerFooter: {
    width: 500,
    height: 47.09,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    display: "inline-flex",
  },
  text: {
    color: "#292929",
    fontSize: 12,
    fontFamily: "Helvetica",
  },
  containerContact: {
    width: 500,
    height: 110,
    padding: 12,
    backgroundColor: "#F15C41",
    borderRadius: 16,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  contentContainerContact: {
    flexDirection: "row",
    alignItems: "center",
  },
  image: {
    width: 50,
    height: 50,
    borderRadius: 9999,
    border: "0.5 solid white",
  },

  textAvatar: {
    fontSize: 12,
    color: "#2875B4",
  },
  circle: {
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 4,
    paddingRight: 6,
    borderRadius: 800,
    border: "0.5px solid #FBCBC2",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#EEF4F9",
  },
  rotatedBorder: {
    width: 50,
    height: 0,
    transform: "rotate(90deg)",
    transformOrigin: "0 0",
    borderBottom: "0.50px solid #F3765F",
    padding: 5,
  },
  bullet: {
    marginRight: 15,
    marginTop: -10,
  },
  textContainer: {
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    marginLeft: 20,
  },
  name: {
    color: "white",
    fontSize: 8,
    fontFamily: "Helvetica",
    fontWeight: "700",
    wordWrap: "break-word",
  },
  titleContact: {
    color: "white",
    fontSize: 8,
    fontFamily: "Helvetica",
    fontWeight: "400",
  },
  emailPhoneContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 10,
  },
  contactInfo2: {
    color: "white",
    fontSize: 8,
    fontFamily: "Helvetica",
    fontWeight: "400",
    flexDirection: "row",
    alignItems: "center",
  },
  containerTable: {
    width: 500,
    height: "auto",
    backgroundColor: "white",
    borderRadius: 8,
    overflow: "hidden",
    border: "1px solid #2875B4",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  headerRow: {
    alignSelf: "stretch",
    height: 32,
    padding: 16,
    backgroundColor: "#EEF4F9",
    borderBottom: "1px solid #2875B4",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 16,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  headerCell: {
    color: "#292929",
    fontSize: 10,
    fontFamily: "Helvetica",
    fontWeight: "700",
  },
  row: {
    alignSelf: "stretch",
    justifyContent: "center",
    alignItems: "flex-start",
    flexDirection: "row",
  },
  cell: {
    flex: "1 1 0",
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 4,
    paddingBottom: 4,
    border: "1px solid #2875B4",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  valueLabel: {
    color: "#292929",
    fontSize: 8,
    fontFamily: "Helvetica",
    fontWeight: "400",
    wordWrap: "break-word",
  },
  value: {
    color: "#292929",
    fontSize: 6,
    fontFamily: "Helvetica",
    fontWeight: "400",
    wordWrap: "break-word",
  },
  totalCell: {
    flex: "1 1 0",
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 8,
    paddingBottom: 8,
    backgroundColor: "white",
    border: "1px solid #2875B4",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: 8,
  },
  totalLabel: {
    color: "#292929",
    fontSize: 8,
    fontFamily: "Helvetica",
    fontWeight: "400",
    wordWrap: "break-word",
  },
  totalValue: {
    color: "#292929",
    fontSize: 6,
    fontFamily: "Helvetica",
    fontWeight: "700",
    wordWrap: "break-word",
  },
  totalRow: {
    alignSelf: "stretch",
    height: 32,
    paddingLeft: 16,
    paddingRight: 16,
    backgroundColor: "#BAD3E7",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    gap: 16,
    borderBottomLeftRadius: 9,
    borderBottomRightRadius: 9,
  },
  totalText: {
    color: "#292929",
    fontSize: 10,
    fontFamily: "Helvetica",
    fontWeight: "700",
    wordWrap: "break-word",
  },
  footer: {
    paddingLeft: 48,
    paddingRight: 48,
    position: "fixed",
    bottom: "0px",
    width: "100%",
    height: "8%",
  },
  mainContainer: {
    margin: 0,
    height: "100%",
  },
  wrapper: {
    minHeight: "87%",
  },
  titleContainer: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    margin: 0,
    padding: 0,
  },
});
