import { Text, View } from "@react-pdf/renderer";
import { convertCurrencyNumber } from "../../utils/function";
import { styles } from "./stylesxx";

const Tributes = ({ data }) => {
  const { VALUETBT, ANTTRIBU } = data;
  return (
    <View style={styles.containerTable}>
      <View style={styles.headerRow}>
        <Text style={styles.headerCell}>Tributos ( COP )</Text>
      </View>
      {VALUETBT.map((item, index) => {
        let color = "white";
        if (index % 2 !== 0) {
          color = "#EEF4F9";
        }
        return (
          <View key={`tribute-${index}`} style={[styles.row, { backgroundColor: color }]}>
            <View style={styles.cell}>
              <Text style={styles.valueLabel}>Declaración</Text>
              <Text style={styles.value}>{item.LIMACEXX !== "" ? item.LIMACEXX : "\n"}</Text>
            </View>
            <View style={styles.cell}>
              <Text style={styles.valueLabel}>Gravamen</Text>
              <Text style={styles.value}>$ {convertCurrencyNumber(item.ANTGRVMX)}</Text>
            </View>
            <View style={styles.cell}>
              <Text style={styles.valueLabel}>IVA</Text>
              <Text style={styles.value}>$ {convertCurrencyNumber(item.ANTIVAXX)}</Text>
            </View>
            <View style={styles.cell}>
              <Text style={styles.valueLabel}>Salvaguardia</Text>
              <Text style={styles.value}>$ {convertCurrencyNumber(item.ANTSALVX)}</Text>
            </View>
            <View style={styles.cell}>
              <Text style={styles.valueLabel}>Compensa...</Text>
              <Text style={styles.value}>$ {convertCurrencyNumber(item.ANTCOMPX)}</Text>
            </View>
            <View style={styles.cell}>
              <Text style={styles.valueLabel}>Antidump...</Text>
              <Text style={styles.value}>$ {convertCurrencyNumber(item.ANTDUMPX)}</Text>
            </View>
            <View style={styles.cell}>
              <Text style={styles.totalLabel}>Sanción</Text>
              <Text style={styles.value}>$ {convertCurrencyNumber(item.ANTSANCX)}</Text>
            </View>
            <View style={styles.cell}>
              <Text style={styles.totalLabel}>Rescate</Text>
              <Text style={styles.value}>$ {convertCurrencyNumber(item.ANTRESCT)}</Text>
            </View>
            <View style={styles.cell}>
              <Text style={styles.totalLabel}>Total</Text>
              <Text style={styles.totalValue}>$ {convertCurrencyNumber(item.ANTTOTAL)}</Text>
            </View>
          </View>
        );
      })}
      <View style={styles.totalRow}>
        <Text style={styles.totalText}>TOTAL TRIBUTOS</Text>
        <Text style={[styles.totalText, { textAlign: "right" }]}>$ {convertCurrencyNumber(ANTTRIBU)}</Text>
      </View>
    </View>
  );
};

export default Tributes;
