import { useSelector } from "react-redux";
import { useGetDataGraphMutation } from "../../../libs/redux/slices/Graficsx/graficsxApiSlice";
import { getDataUser } from "../../../libs/redux/slices/authSlice/authSlice";
import { useEffect, useState } from "react";

export function useDataGraph({ MODIDXXX, PROIDXXX }) {
  const [filtersJson, setFiltersJson] = useState({
    filtersJson: JSON.stringify([
      { "TARCYYYY.ESTAIDXX": { IN: [100, 102, 105] } },
      { "TARCYYYY.REGESTXX": { IN: ["ACTIVO"] } }
    ]),
  });
  const [dataGraph, setDataGraph] = useState();
  const { USRIDXXX, CARIDXXX } = useSelector(getDataUser);
  const [getDataGraph] = useGetDataGraphMutation();

  const handleResetFilterJson = () => {
    setFiltersJson({
      filtersJson: JSON.stringify([
        { "TARCYYYY.ESTAIDXX": { IN: [100, 102, 105] } },
        { "TARCYYYY.REGESTXX": { IN: ["ACTIVO"] } }
      ]),
    });
  };

  const handleSetFilterJson = (newFilters) => {
    setFiltersJson(newFilters);
  };

  useEffect(() => {
    const handleGetData = async () => {
      const { data } = await getDataGraph({ filters: filtersJson, USRIDXXX, CARIDXXX });
      if (data) {
        setDataGraph(data);
      }
    };
    handleGetData();
  }, [CARIDXXX, USRIDXXX, filtersJson, getDataGraph]);

  return { dataGraph, handleSetFilterJson, handleResetFilterJson };
}
