/**
 * Componente para el reenvio de Anticipos
 * @author Oscar Rosero
 * @since 2023-12-22
 * @returns Component
 */

import { Box, Button, CodeIcon, Divider, Input, Label, Stack, StackRow, shootToast } from "codekit";
import { useState } from "react";
import Pdfviewx from "./pdf/Pdfviewx";
import { useSendPDF } from "./utils/useSendPDF";
import { useLazyPaquetesDocumentalesQuery } from "../../../../libs/redux/slices/Genioxxx/genioxxxApiSlice";
import { useLazyGetDataAdvanceQuery } from "../../../../libs/redux/slices/Modimpxx/Anticipo/anticipoApiSlice";

const Resendxx = ({ dataError, setDataError, infoUser, setIsAnticipo }) => {
  const [isLoading, setIsLoaging] = useState({});
  const [isDisabled, setIsDisabled] = useState({});
  const debugPDF = false;
  const [dataClient, setDataClient] = useState([]);
  const [dataPDF, setDataPDF] = useState([]);
  const [getDataAdvance] = useLazyGetDataAdvanceQuery();
  const { handleGeneratePdf, sendEmailAdvance } = useSendPDF();
  const [paquetesDocumentales] = useLazyPaquetesDocumentalesQuery();

  //Funcion para el reenvio segun error
  const handleResend = async (ANTCIDXX, ESTADOTA, index) => {
    setIsLoaging((state) => ({ ...state, [index]: true }));
    handleSetDisabled(true);
    await getDataAdvance({ filters: { ANTCIDXX, REGESTXX: "ACTIVO" } })
      .unwrap()
      .then(async (resp) => {
        const { data } = resp;
        const dataPDF = {
          ...data.ANTCYYYY[0],
          VALUEUSD: data.VALUEUSD,
          VALUETBT: data.VALUETBT,
          PAGOSTER: data.PAGOSTER,
          INGREPRO: data.INGREPRO,
          CONTACTX: data.CONTACTX,
        };
        setDataPDF(dataPDF);
        const clientData = {
          CLIIDXXX: dataPDF.CLIIDXXX,
          CLINOMXX: dataPDF.CLINOMXX,
          id: dataPDF.CLIIDXXX,
          CLITELXX: dataPDF.CLITELXX,
          CLIDIRXX: dataPDF.CLIDIRXX,
          MUNDESXX: dataPDF.MUNDESXX,
        };
        if (ESTADOTA === "ERROR CARGUE PDF") {
          setDataClient(clientData);
          await handleGeneratePdf(ANTCIDXX, dataPDF, clientData, true);
          handleSetDataError(ANTCIDXX);
        } else {
          //Consulto la url del archivo en Genio para el reenvio del correo
          await paquetesDocumentales({ filters: { IDARCHXX: dataPDF.IDARCHXX } })
            .unwrap()
            .then(async (resp) => {
              const { data } = resp;
              const DOCDURLX = data[0]?.DOCDURLX ?? 0;
              const dataSend = { ...dataPDF };
              dataSend.DOCDURLX = DOCDURLX;
              if (DOCDURLX !== 0 && DOCDURLX !== "") {
                await sendEmailAdvance(dataSend)
                  .unwrap()
                  .then(() => {
                    shootToast({
                      variant: "success",
                      text: "el correo se ha enviado con Exito!",
                      duration: 5000,
                      autoHide: true,
                    });
                    handleSetDataError(dataPDF.ANTCIDXX);
                  })
                  .catch();
              } else {
                setIsLoaging({});
                setIsDisabled({});
                shootToast({
                  variant: "warning",
                  text: "No se encontro el documento el Genio Documental",
                  duration: 5000,
                  autoHide: true,
                });
              }
            })
            .catch();
        }
      })
      .catch();
  };

  //Funcion para inhabilitar los botones de reenvio
  const handleSetDisabled = (isDisabled) => {
    let disables = {};
    for (let i = 0; i <= dataError.length; i++) {
      disables[i] = isDisabled;
    }
    setIsDisabled(disables);
  };

  //Funcion para sacar del listado los errores reprocesados
  const handleSetDataError = (id) => {
    const newData = dataError.filter((item) => item.ANTCIDXX !== id);
    setDataError(newData);
    setIsLoaging({});
    setIsDisabled({});
  };
  return dataError.length > 0 ? (
    <>
      <Box mb={8}>
        <Box mb={8}>
          <Divider text="Solicitudes Sin Enviar" color="accent-600" />
        </Box>
        <Box mb={8}>
          {dataError.map((item, index) => (
            <Box mb={6} key={`row-${index}`}>
              <Stack
                gap={7}
                responsiveColumns={{
                  initial: 1,
                  small: 3,
                  large: 3,
                  extraLarge: 3,
                }}
              >
                <StackRow>
                  <Label>Id del Anticipo</Label>
                  <Input value={item.ANTCIDXX} readOnly />
                </StackRow>
                <StackRow>
                  <Label>Estado</Label>
                  <Input value={item.ESTADOTA} readOnly />
                </StackRow>
                <StackRow>
                  <Box mt={7}>
                    <Button
                      type="button"
                      size="m"
                      isLoading={isLoading[index]}
                      variant="secondary"
                      onClick={() => handleResend(item.ANTCIDXX, item.ESTADOTA, index)}
                      disabled={isDisabled[index]}
                    >
                      <CodeIcon variant="send-arrow-up" color="accent-600" />
                    </Button>
                  </Box>
                </StackRow>
              </Stack>
            </Box>
          ))}
        </Box>
      </Box>
      {debugPDF && (
        <Pdfviewx infoUser={infoUser} dataPDF={dataPDF} dataClient={dataClient} setIsAnticipo={setIsAnticipo} />
      )}
    </>
  ) : (
    <></>
  );
};

export default Resendxx;
