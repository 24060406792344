import { Autocomplete, Box, Button, Divider, FieldError, Input, Label, Stack, StackRow } from "codekit";
import React, { useEffect, useState } from "react";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { useGetOwnConceptsMutation } from "../../../../libs/redux/slices/Modimpxx/Anticipo/anticipoApiSlice";
import { useDispatch } from "react-redux";
import { setTotalOwn } from "../../../../libs/redux/slices/Modimpxx/Anticipo/anticipoSlice";
import { convertCurrencyNumber } from "../utils/function";

/**
 * Componente hijo para el pintado de la seccion Ingresos propios
 * @returns Component
 */
const Ingrepro = ({ isCreating, isVisualize }) => {
  const dispatch = useDispatch();
  const { register, setValue, formState, clearErrors, control } = useFormContext();
  const { errors } = formState;
  //Grilla Pagos a Terceros
  const { fields, append, remove, update } = useFieldArray({
    name: "INGREPRO",
  });

  const [getOwnConcepts] = useGetOwnConceptsMutation();
  const [results, setResults] = useState([]);
  const [total, setTotal] = useState(0);

  //Funcion para el autocompletado
  const search = async (value, cb) => {
    return await getOwnConcepts({
      filter: value,
    })
      .unwrap()
      .then((response) => {
        setResults(response.data);
      })
      .catch((err) => {
        console.log("entro en el catch", err);
      })
      .finally(() => {
        cb();
      });
  };

  const handleChangeAutocomplete = (context, item, index, empty = false) => {
    const value = !empty ? context.item.id : "";
    const label = !empty ? context.item.label : null;
    let exist = [];
    if (!empty) {
      exist = fields.filter((element) => element.CONCOBID === context.item.id);
    }
    if (exist.length === 0) {
      update(index, { ...item, CONCOBID: value, CONCOBDE: label });
      clearErrors(`INGREPRO.${index}.CONCOBID`);
    }
  };

  const INGREPRO = useWatch({ name: "INGREPRO", control });

  //Hook para cambiar el valor total
  useEffect(() => {
    if (INGREPRO) {
      let total = 0;
      INGREPRO.forEach((element) => {
        total += parseFloat(element.ANTPVLRX);
      });
      setTotal(total);
      setValue("ANTPVLRX", total);
      dispatch(setTotalOwn(total));
    }
  }, [INGREPRO, dispatch, setValue]);

  return (
    <>
      <Box mb={8}>
        <Divider text="Ingresos Propios" color="accent-600" />
      </Box>
      <Box mb={8}>
        <Label>Ingresos Propios</Label>
        <Box bg="neutral-200" border={1} borderColor="neutral-300" spacing={3} rounded="md">
          <Stack gap={2}>
            {fields.map((item, index) => (
              <Box key={item.id} spacingX={2} spacingTop={2} spacingBottom={4} rounded="md" bg="white">
                <Stack gap={4} columnsVariant="form-2">
                  <StackRow>
                    <Label>Propios</Label>
                    <Autocomplete
                      {...register(`INGREPRO.${index}.CONCOBID`)}
                      placeholder="Concepto"
                      textLoading="Buscando..."
                      textNoResults="No se encontraron registros"
                      minLengthToSearch={2}
                      selected={item.CONCOBDE}
                      searchResults={results}
                      onChangeSearch={(value, cb) => search(value, cb)}
                      onItemClick={(context) => {
                        handleChangeAutocomplete(context, item, index);
                      }}
                      onResetSelected={(context) => handleChangeAutocomplete(context, item, index, true)}
                      readOnly={isCreating || isVisualize}
                    />
                  </StackRow>
                  <StackRow>
                    <Stack gap={4} columnsVariant="form-2">
                      <StackRow>
                        <Label>Valor</Label>
                        <Input
                          type="number"
                          {...register(`INGREPRO.${index}.ANTPVLRX`, { pattern: /^\d{1,9}$/ })}
                          onBlur={(event) => {
                            update(index, { ...item, ANTPVLRX: parseFloat(event.target.value) });
                          }}
                          isInvalid={errors.INGREPRO && errors.INGREPRO[index] && errors.INGREPRO[index].ANTPVLRX}
                          readOnly={isCreating || isVisualize}
                        />
                        {errors.INGREPRO && errors.INGREPRO[index] && errors.INGREPRO[index].ANTPVLRX && (
                          <FieldError>El campo Valor debe ser mayor a 0 y maximo 9 enteros sin decimales</FieldError>
                        )}
                      </StackRow>
                      <StackRow>
                        <Button
                          type="button"
                          onClick={() => remove(index)}
                          variant="error-light"
                          icon="trash"
                          disabled={fields.length === 1 || isVisualize}
                          size="m"
                          onlyIcon
                          inactive={isCreating}
                        />
                      </StackRow>
                    </Stack>
                  </StackRow>
                </Stack>
              </Box>
            ))}
            <StackRow>
              <Button
                type="button"
                variant="secondary-light"
                icon="plus"
                disabled={isCreating}
                size="l"
                onClick={() => append({ CONCOBID: "", ANTPVLRX: 0, CONCOBDE: null })}
                inactive={isCreating || isVisualize}
              >
                Agregar otro
              </Button>
            </StackRow>
          </Stack>
        </Box>
      </Box>
      <Box mb={8} bg="success-100" rounded="md" spacing={2} border={1} borderColor="success-300">
        <Stack columnsVariant="form-2">
          <StackRow>
            <Label weight="medium">Total Ingresos Propios</Label>
          </StackRow>
          <StackRow>
            <Label alignment="right">$ {convertCurrencyNumber(total)}</Label>
            <input type="hidden" {...register("ANTPVLRX")} />
          </StackRow>
        </Stack>
      </Box>
    </>
  );
};

export default Ingrepro;
