import { Box, Button, Divider, FieldError, Input, Label, Select, Stack, StackRow, Checkbox, shootToast } from "codekit";
import React, { useEffect, useState } from "react";
import { useFieldArray, useFormContext, Controller, useWatch } from "react-hook-form";
import { useDispatch } from "react-redux";
import {
  useGetConceptsQuery,
  useLazyGetClientByDataQuery,
} from "../../../../libs/redux/slices/Modimpxx/Anticipo/anticipoApiSlice";
import { setTotalThird } from "../../../../libs/redux/slices/Modimpxx/Anticipo/anticipoSlice";
import { convertCurrencyNumber } from "../utils/function";

/**
 * Componente hijo para el pintado de la seccion pagos a terceros
 * @returns Component
 */
const Pagthird = ({ isCreating, client, isVisualize }) => {
  const dispatch = useDispatch();
  const { data: dataConcept, isSuccess: succesConcept } = useGetConceptsQuery();
  const [dataConcepts, setDataConcepts] = useState([]);
  const { register, setValue, formState, clearErrors, control } = useFormContext();
  const { errors } = formState;
  const [total, setTotal] = useState(0);
  const [fetchData, { data: clientData, isSuccess }] = useLazyGetClientByDataQuery();
  const [consult, setConsult] = useState(false);

  //Hook para el seteo de la informacion de los conceptos de causacion
  useEffect(() => {
    if (succesConcept) {
      const { data } = dataConcept;
      const results = data.map((element) => {
        return { value: element.CONIDXXX, text: element.CONDESXX };
      });
      setDataConcepts(results);
    }
  }, [succesConcept, dataConcept]);

  //Grilla Pagos a Terceros
  const { fields, append, remove, update } = useFieldArray({
    name: "PAGOSTER",
  });

  //Funcion para validar que no se repita la opcion seleccionada
  const handleChangeSelect = (context, item, index) => {
    const exist = fields.filter((element) => element.CONIDXXX === context.option.value);
    if (exist.length === 0) {
      update(index, { ...item, CONIDXXX: context.option.value, CONDESXX: context.option.text });
      clearErrors(`PAGOSTER.${index}.CONIDXXX`);
    }
  };

  const PAGOSTER = useWatch({ name: "PAGOSTER", control });

  //Hook para cambiar el valor total
  useEffect(() => {
    if (PAGOSTER) {
      let total = 0;
      PAGOSTER.forEach((element) => {
        total += parseFloat(element.ANTEVLRX);
      });
      setTotal(total);
      setValue("ANTEVLRX", total);
      dispatch(setTotalThird(total));
    }
  }, [PAGOSTER, dispatch, setValue]);

  //Funcion para validar el valor de un campo tipo number
  const handleChangeValue = (item, value = 0, index) => {
    const number = value === "" ? 0 : value;
    update(index, { ...item, ANTEVLRX: parseFloat(number) });
  };

  // Lógica cuando se marca el checkbox "Recordar conceptos"
  const handleCheckDim = async (isChecked) => {
    if (isChecked && client) {
      setConsult(true);
      fetchData({ CLIIDXXX: client });
    } else {
      setConsult(false);
    }
  };

  // funcion que consulta Si el Cliente cuenta con una peticn anterior de Anticipo
  useEffect(() => {
    if (isSuccess && consult) {
      const { data } = clientData;
      if (data.length === 0) {
        shootToast({
          text: "El cliente no tiene solicitudes de Anticipo previas.",
          variant: "warning",
          autoHide: true,
          duration: 5000,
        });
      } else {
        remove();
        data.forEach((item) => {
          append({ CONIDXXX: item.CONIDXXX, CONDESXX: item.CONDESXX, ANTEVLRX: "" });
        });
      }
    }
  }, [clientData, isSuccess, append, remove, consult]);

  return (
    <>
      <Box mb={8}>
        <Divider text="Pagos a Terceros" color="accent-600" />
      </Box>
      <Box mb={8}>
        <Label>Pagos a Terceros</Label>
        <Box bg="neutral-200" border={1} borderColor="neutral-300" spacing={3} rounded="md">
          <Stack gap={2}>
            {fields.map((item, index) => (
              <Box key={item.id} spacingX={2} spacingTop={2} spacingBottom={4} rounded="md" bg="white">
                <Stack gap={4} columnsVariant="form-2">
                  <StackRow>
                    <Label>Terceros</Label>
                    <Select
                      {...register(`PAGOSTER.${index}.CONIDXXX`)}
                      options={dataConcepts}
                      value={item.CONIDXXX}
                      onChangeValue={(context) => {
                        handleChangeSelect(context, item, index);
                      }}
                      readOnly={isCreating || isVisualize}
                    />
                  </StackRow>
                  <StackRow>
                    <Stack gap={4} columnsVariant="form-2">
                      <StackRow>
                        <Label>Valor</Label>
                        <Input
                          type="number"
                          {...register(`PAGOSTER.${index}.ANTEVLRX`, { pattern: /^\d{1,9}$/ })}
                          onBlur={(event) => {
                            handleChangeValue(item, event.target.value, index);
                          }}
                          isInvalid={errors.PAGOSTER && errors.PAGOSTER[index] && errors.PAGOSTER[index].ANTEVLRX}
                          readOnly={isCreating || isVisualize}
                        />
                        {errors.PAGOSTER && errors.PAGOSTER[index] && errors.PAGOSTER[index].ANTEVLRX && (
                          <FieldError>El campo Valor debe ser mayor a 0 y maximo 9 enteros sin decimales</FieldError>
                        )}
                      </StackRow>
                      <StackRow>
                        <Button
                          type="button"
                          onClick={() => remove(index)}
                          variant="error-light"
                          icon="trash"
                          disabled={fields.length === 1 || isVisualize}
                          size="m"
                          onlyIcon
                          inactive={isCreating}
                        />
                      </StackRow>
                    </Stack>
                  </StackRow>
                </Stack>
              </Box>
            ))}
            <StackRow>
              <Button
                type="button"
                variant="secondary-light"
                icon="plus"
                disabled={isCreating || isVisualize}
                size="l"
                onClick={() => append({ CONIDXXX: "", ANTEVLRX: 0 })}
                inactive={isCreating}
              >
                Agregar otro
              </Button>
            </StackRow>
          </Stack>
        </Box>
      </Box>
      {/* "Recordar conceptos" */}
      <Box mb={8}>
        <Stack gap={2}>
          <StackRow>
            <Controller
              control={control}
              name="rememberConcepts"
              render={({ field }) => (
                <Checkbox
                  checked={field.value}
                  onChange={(e) => {
                    field.onChange(e.target.checked);
                    handleCheckDim(e.target.checked);
                  }}
                  readOnly={isVisualize}
                >
                  Recordar conceptos
                </Checkbox>
              )}
            />
          </StackRow>
        </Stack>
      </Box>
      <Box mb={8} bg="success-100" rounded="md" spacing={2} border={1} borderColor="success-300">
        <Stack columnsVariant="form-2">
          <StackRow>
            <Label weight="medium">Pagos a Terceros</Label>
          </StackRow>
          <StackRow>
            <Label alignment="right">$ {convertCurrencyNumber(total)}</Label>
            <input type="hidden" {...register("ANTEVLRX")} />
          </StackRow>
        </Stack>
      </Box>
    </>
  );
};

export default Pagthird;
