/**
 * Componente para la depuracion de la generacion del pdf
 */

import { PDFViewer } from "@react-pdf/renderer";
import React from "react";
import Pagemain from "./Pagemain";
import { Button, Flexbox, Heading, Modal, ModalBody, ModalHeader } from "codekit";

const Pdfviewx = ({ infoUser, dataPDF, dataClient, setIsAnticipo }) => {
  return (
    <Modal size="xl" isOpen={true} isTop={true} style={{ position: "fixed", zIndex: 1000 }}>
      <ModalHeader>
        <Flexbox justifyContent="between" alignItems="center">
          <Heading variant="heading-6">Solicitud de Anticipo</Heading>
          <Button
            variant="secondary"
            icon="close"
            onlyIcon
            size="m"
            onClick={() => {
              setIsAnticipo(false);
            }}
          />
        </Flexbox>
      </ModalHeader>
      <ModalBody size="sm">
        <PDFViewer style={{ width: "100%", height: "100vh", color: "white" }}>
          <Pagemain infoUser={infoUser} dataPDF={dataPDF} dataClient={dataClient} />
        </PDFViewer>
      </ModalBody>
    </Modal>
  );
};

export default Pdfviewx;
