//Acciones del tracking para cada registro
export const allowedActionsRow = (
  optionsMenuButton,
  setIsAnticipo,
  setIsConsuldo,
  setIsLegalize,
  setIsCauPay,
  setIsConsulDoRDO
) => {
  let actions = {};
  let rowActions = [];
  let buttonLegalize = {};
  let buttonAdvance = {};
  let buttonAdvanceProvider = {};
  let buttonCausationPayment = {};
  let buttonRdo = {};
  Object.values(optionsMenuButton).forEach((value) => {
    switch (value.MENIDXXX) {
      case 100: //Legalizar anticipo
        buttonLegalize = {
          id: "legalize",
          title: value.OPCDESXX,
          icon: value.MENUICOX,
          handler: setIsLegalize,
        };
        break;
      case 101: //Anticipo Cliente
        buttonAdvance = {
          id: "advance",
          title: value.OPCDESXX,
          icon: value.MENUICOX,
          handler: setIsAnticipo,
        };
        break;
      case 102: //Anticipo Proveedor
        buttonAdvanceProvider = {
          //
          id: "advanceProvider",
          title: value.OPCDESXX,
          icon: value.MENUICOX,
          handler: setIsConsuldo,
        };
        break;
      case 103: //Pago Causaciones
        buttonCausationPayment = {
          id: "causationPayment",
          title: value.OPCDESXX,
          icon: value.MENUICOX,
          handler: setIsCauPay,
        };
        break;
      case 104: //RDO
        buttonRdo = {
          id: "rdo",
          title: value.OPCDESXX,
          icon: value.MENUICOX,
          handler: setIsConsulDoRDO,
        };
        break;
      default:
        break;
    }
  });
  return {
    ...actions,
    rowActions,
    buttonLegalize,
    buttonAdvance,
    buttonAdvanceProvider,
    buttonCausationPayment,
    buttonRdo,
  };
};
