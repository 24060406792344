import { Text, View, Image, Circle } from "@react-pdf/renderer";
import Tablexxx from "./Tablexxx";
import { Envelope, PhoneVolume } from "./iconsxxx";
import { styles } from "./stylesxx";
import Tributes from "./Tributes";
import Thirdsxx from "./Thirdsxx";
import Totalxxx from "./Totalxxx";
import Observsx from "./Observsx";
import generateAvatarLetters from "../../../../../utils/avatar-letters-generator";
import { getActualDate } from "../../../../../utils/globalFunctions";

const Headerxx = ({ infoUser, data, dataClient, urlAws }) => {
  let date = new Date(getActualDate());
  let options = { month: "long" };
  let anio = date.getFullYear();
  let stringMonth = date.toLocaleDateString("es-ES", options);
  return (
    <View>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={{ width: "100%" }}>
            <Image src={`${urlAws}/images/header_offer.png`} />
          </View>
        </View>
      </View>
      <View style={styles.container}>
        <View style={styles.header}>
          <View style={styles.titleContainer}>
            <Text style={styles.title}>
              Solicitud de anticipo{"\n"}
              <Text style={styles.textClient}>
                AGENCIA DE ADUANAS SIACO S. A. S. NIVEL 1
                {"\n"}
                NIT: 800.251.957-1
              </Text>
            </Text>
          </View>
          <Text style={styles.date}>
            {stringMonth.charAt(0).toUpperCase() + stringMonth.slice(1)} de {anio}
          </Text>
        </View>
        <View style={styles.boxContainer}>
          <View style={styles.box}>
            <View style={styles.infoClient}>
              <Text style={styles.titleClient}>Datos cliente</Text>
              <View style={styles.dataClient}>
                <Text style={styles.textClient}>Señores: {dataClient.CLINOMXX}</Text>
                <Text style={styles.textClient}>NIT / CC: {dataClient.CLIIDXXX}</Text>
                <Text style={styles.textClient}>Dirección: {`${dataClient.MUNDESXX ?? ""} ${dataClient.CLIDIRXX}`}</Text>
                <Text style={styles.textClient}>Telefono: {dataClient.CLITELXX ?? ""}</Text>
                <Text style={styles.textClient}>Contacto: {data.CONTACTX[0]?.CONTACTE}</Text>
                <Text style={styles.textClient}>Ciudad: {dataClient.MUNDESXX}</Text>
              </View>
            </View>
          </View>
          <View style={styles.box}>
            <View style={styles.infoClient}>
              <Text style={[styles.titleClient, { alignSelf: "stretch" }]}>Datos de la operación</Text>
              <View style={[styles.dataClient, { alignSelf: "stretch" }]}>
                <Text style={[styles.textClient, { alignSelf: "stretch" }]}>N° de Pedido: {data.DOIPEDXX}</Text>
                <Text style={[styles.textClient, { alignSelf: "stretch" }]}>T.R.M: {data.TRMTASXX}</Text>
                <Text style={[styles.textClient, { alignSelf: "stretch" }]}>
                  Consecutivo SLB: {data.ANTCOSBL ?? ""}
                </Text>
              </View>
            </View>
          </View>
        </View>
        {data.ANTTCIFX > 0 && (
          <View style={{ paddingTop: 10 }}>
            <Tablexxx data={data} />
          </View>
        )}
        {data.ANTTRIBU > 0 && (
          <View style={{ paddingTop: 10 }}>
            <Tributes data={data} />
          </View>
        )}
        {parseFloat(data.ANTEVLRX) + parseFloat(data.ANTPVLRX) > 0 && (
          <View style={{ paddingTop: 10 }} wrap={false}>
            <Thirdsxx data={data} />
          </View>
        )}
        <View style={{ paddingTop: 10 }} wrap={false}>
          <Totalxxx data={data} />
        </View>
        <View style={{ paddingTop: 10, paddingBottom: 10 }} wrap={false}>
          <Observsx data={data} />
        </View>
      </View>
      <View style={[styles.container, { paddingTop: 10 }]} wrap={false}>
        <View style={styles.containerContact}>
          <View style={styles.contentContainerContact}>
            <View style={styles.circle}>
              <Circle cx="60" cy="60" r="15" fill="tomato" stroke="white">
                {/* <Image src={`${urlAws}/images/users/1022940897.png`} style={styles.image} /> */}
                <Text style={styles.textAvatar}>{generateAvatarLetters(infoUser.USRNOMXX)}</Text>
              </Circle>
            </View>
            <View style={styles.textContainer} wrap={false}>
              <View>
                <View style={styles.rotatedBorder}></View>
              </View>
              <Text style={styles.name}>{infoUser.USRNOMXX}</Text>
              <Text style={styles.titleContact}>{infoUser.CARDESXX}</Text>
            </View>
            <View style={styles.textContainer}>
              <View style={styles.emailPhoneContainer}>
                <View style={styles.bullet}>
                  <Envelope color="white" />
                </View>
                <Text style={styles.contactInfo2}>{infoUser.USREMAXX}</Text>
              </View>
              <View style={styles.emailPhoneContainer}>
                <View style={styles.bullet}>
                  <PhoneVolume color="white" />
                </View>
                <Text style={styles.contactInfo2}>{infoUser.USRCELUL}</Text>
              </View>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

export default Headerxx;
