import { Document, Page, Text, View } from "@react-pdf/renderer";
import Headerxx from "./Headerxx";

import { styles } from "./stylesxx";
import { SiacoLogo } from "./iconsxxx";

const Pagemain = ({infoUser, dataPDF, dataClient }) => {
  const urlAws = process.env.REACT_APP_URL_BUCKET_AWS;
  return (
    <Document>
      <Page size="A4" style={styles.mainContainer} wrap>
        <View
          style={{
            borderBottom: "1px solid #E6E6E6",
          }}
          render={({ pageNumber }) =>
            pageNumber > 1 && (
              <View
                style={{
                  with: 500,
                  height: "5%",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingTop: 15,
                }}
              >
                <Text
                  style={{
                    color: "#292929",
                    fontSize: 10,
                    fontFamily: "Helvetica",
                    fontWeight: 700,
                  }}
                >
                  Solicitud de Anticipo
                </Text>
                <SiacoLogo />
              </View>
            )
          }
          fixed
        />
        <View style={styles.wrapper}>
          <Headerxx infoUser={infoUser} data={dataPDF} dataClient={dataClient} urlAws={urlAws} />
        </View>
        <View style={[styles.footer]} fixed>
          <View style={styles.containerFooter}>
            <SiacoLogo width={50} />
            <Text style={styles.text}>Tel: (601) 4252600</Text>
            <Text style={styles.text}>Av Cr 97 N° 24C 80</Text>
            <Text style={styles.text}>Bogotá, Colombia</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default Pagemain;
