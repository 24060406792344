import {
  Autocomplete,
  Box,
  BoxStatus,
  Button,
  Divider,
  FieldError,
  Flexbox,
  Input,
  Label,
  Select,
  Stack,
  StackRow,
  Textarea,
  shootToast,
  Checkbox,
} from "codekit";

import { useFieldArray, useWatch, Controller, useFormContext } from "react-hook-form";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  useLazyGetAdvanceWithErrorsQQuery,
  useGetAutoClientMutation,
  useGetTRMQuery,
  useLazyGetConsecutiveSLBQuery,
  useLazyGetContactAdvanceQuery,
  useLazyGetDataDimQuery,
  useLazyGetAutoClientQueryQuery,
  useLazyGetClientByOrderQuery,
  useLazyGetClientByFactQuery,
} from "../../../../libs/redux/slices/Modimpxx/Anticipo/anticipoApiSlice";
import {
  getTotalGeneral,
  getTRM,
  setMasiveValueTribute,
  setMasiveValueUsd,
  setTRM,
} from "../../../../libs/redux/slices/Modimpxx/Anticipo/anticipoSlice";

import Aduanusd from "./Aduanusd";
import Antribux from "./Antribux";
import Pagthird from "./Pagthird";
import Ingrepro from "./Ingrepro";
import { convertCurrencyNumber } from "../utils/function";
import { getPatterEmail } from "../../../../utils/globalFunctions";
import { valuesCif, valuesCONTACTX, valuesUsd } from "./utils/defValue";
import { useGetAutocompleteWithParamsMutation } from "../../../../libs/redux/slices/autocompSlice/autocompApiSlice";

/**
 * Componente para el pintado del formulario de la anticipo cliente
 * @returns
 */
const Antcform = ({ data = null, isCreating, setOrder, setDataError, setDataClient, isVisualize }) => {
  const dispatch = useDispatch();
  const [getConsecutiveSLB] = useLazyGetConsecutiveSLBQuery();

  const {
    register,
    clearErrors,
    setValue,
    control,
    setError,
    formState: { errors },
  } = useFormContext();

  const [getAutoClient] = useGetAutoClientMutation();
  const [results, setResults] = useState([]);
  const [getDataDim] = useLazyGetDataDimQuery();
  const [isLoading, setIsLoading] = useState(false);
  const { data: dataTrm, isSuccess: succesTrm } = useGetTRMQuery();
  const [getContactAdvance] = useLazyGetContactAdvanceQuery();
  const [dataDeclaration, setDataDeclaration] = useState([]);
  const totalGeneral = useSelector(getTotalGeneral);
  const [optionsContact, setOptionsContact] = useState([]);
  const [getAdvanceWithErrors] = useLazyGetAdvanceWithErrorsQQuery();
  const [getAutoClientQuery] = useLazyGetAutoClientQueryQuery();
  const [getClientByOrder] = useLazyGetClientByOrderQuery();
  const [isClient, setIsClient] = useState(false);
  const [resultsDo, setResultsDo] = useState([]);
  const [getAutocompleteWithParams] = useGetAutocompleteWithParamsMutation();
  const [getContactFact] = useLazyGetClientByFactQuery();
  const [isMod, setIsMod] = useState(true);
  const trm = useSelector(getTRM);

  //Grilla valores en aduana USD
  const {
    fields: fieldsUsd,
    append: appendUsd,
    remove: removeUsd,
  } = useFieldArray({
    control,
    name: "VALUEUSD",
  });

  //Grilla valores CIF
  const {
    fields: fieldsCif,
    append: appendCif,
    remove: removeCif,
  } = useFieldArray({
    control,
    name: "VALUETBT",
  });

  //Grilla Contactos
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: "CONTACTX",
  });

  //Hook para el seteo de la TRM
  useEffect(() => {
    if (succesTrm) {
      const { data = [] } = dataTrm;
      let trm = 0;
      if (data.length > 0) {
        trm = parseFloat(data[0]?.TRMTASXX);
      }
      setIsMod(trm !== 0);
      dispatch(setTRM(trm));
    }
  }, [succesTrm, dataTrm, dispatch]);

  //Funcion para el autocompletar de cliente
  const handleSearch = async (value, cb) => {
    return await getAutoClient({
      filter: value,
    })
      .unwrap()
      .then((response) => {
        setResults(response.data);
      })
      .catch((err) => {
        console.log("entro en el catch", err);
      })
      .finally(() => {
        cb();
      });
  };

  //Funcion para setear el valor del cliente en el autocompletar
  const handleChangeSelect = (context, isEmpty) => {
    if (!isEmpty) {
      setDataClient(context.item);
      handlegetContact(context.item.CLIIDXXX ? context.item.CLIIDXXX : CLIIDXXX.split(" - ")[0]);
      handlegetConFact(context.item.CLIIDXXX ? context.item.CLIIDXXX : CLIIDXXX.split(" - ")[0]);
      clearErrors("CLIIDXXX");
      //Si el cliente es SLB genero el consecutivo
      if (context.item.CLIIDXXX === 860002175) {
        getConsecutiveSLB()
          .unwrap()
          .then((resp) => {
            setValue("ANTCOSBL", resp?.data);
          })
          .catch(() => {
            setValue("ANTCOSBL", "");
          });
      }
    } else {
      setDataClient({});
      setOptionsContact([]);
      remove();
      append(valuesCONTACTX);
      setValue("ANTCOSBL", "");
    }
  };

  //Funcion para lanzar query de consulta contactos
  const handlegetContact = useCallback(
    async (CLIIDXXX) => {
      const filters = { CLIIDXXX };
      await getContactAdvance({ filters }, { preferCacheValue: true })
        .unwrap()
        .then((response) => {
          const { data } = response;
          const results = data.map((element) => {
            return { text: element.CONTACCO, value: element.CONTACID, ...element };
          });
          setOptionsContact(results);
        })
        .catch(() => {
          setOptionsContact([]);
        });
    },
    [getContactAdvance]
  );

  //Funcion para lanzar query de condiciones de facturacion
  const handlegetConFact = useCallback(
    async (CLIIDXXX) => {
      const filters = { CLIIDXXX };
      await getContactFact({ filters }, { preferCacheValue: true })
        .unwrap()
        .then((response) => {
          const { data } = response;
          const newOption = data[0]?.CLIRESTR && data[0]?.CLIRESTR.trim() ? data[0]?.CLIRESTR : "";
          setValue("ANTCREST", newOption);
        })
        .catch(() => {
          setValue("ANTCREST", "");
        });
    },
    [getContactFact, setValue]
  );

  const handleChangeContact = (context, item, index) => {
    const exist = fields.filter((element) => element.CONTACID === context.option.value);
    if (exist.length === 0) {
      update(index, {
        ...item,
        CONTACID: context.option.value,
        CONTACCO: context.option.text,
        CONTACNO: context.option.CONTACNO,
        CONTACTE: context.option.CONTACTE,
      });
    }
  };

  useEffect(() => {
    setValue("ANTTOTAL", totalGeneral);
  }, [totalGeneral, setValue]);

  //Funcion para consultar las solicitudes con error
  const getDataErrors = useCallback(
    (order) => {
      getAdvanceWithErrors({
        filters: { DOCNROXX: order, ESTADOTA: { IN: "ERROR ENVIO CORREO,ERROR CARGUE PDF" }, REGESTXX: "ACTIVO" },
      })
        .unwrap()
        .then((response) => {
          setDataError(response.data);
        })
        .catch();
    },
    [getAdvanceWithErrors, setDataError]
  );

  //Query para la busqueda de la informacion del cliente
  const getDataClient = useCallback(
    (client) => {
      getAutoClientQuery({
        filter: client,
      })
        .unwrap()
        .then((response) => {
          setDataClient(response?.data[0]);
          handlegetContact(client);
          handlegetConFact(client);
        })
        .catch((err) => {
          console.log("entro en el catch", err);
        });
      //Si el cliente es SLB genero el consecutivo
      if (client === 860002175) {
        getConsecutiveSLB()
          .unwrap()
          .then((resp) => {
            setValue("ANTCOSBL", resp?.data);
          })
          .catch(() => {
            setValue("ANTCOSBL", "");
          });
      }
    },
    [getAutoClientQuery, handlegetContact, handlegetConFact, getConsecutiveSLB, setValue, setDataClient]
  );

  //Funcion para consultar la informacion de un cliente por pedido
  const handleGetClient = useCallback(
    async (value) => {
      await getClientByOrder({ filters: { DOCNROXX: value } }, { preferCacheValue: true })
        .unwrap()
        .then((response) => {
          const { data } = response;
          if (Object.keys(data).length > 0) {
            setIsClient(true);
            setDataClient(data);
            setValue("CLIIDXXX", `${data.CLIIDXXX} - ${data.CLINOMXX}`);
            setValue("DOCNROXX", `${value}`);
            clearErrors("CLIIDXXX");
            handlegetContact(data.CLIIDXXX);
            handlegetConFact(data.CLIIDXXX);
            if (data.CLIIDXXX === 860002175) {
              getConsecutiveSLB()
                .unwrap()
                .then((resp) => {
                  setValue("ANTCOSBL", resp?.data);
                })
                .catch(() => {
                  setValue("ANTCOSBL", "");
                });
            }
          } else {
            setIsClient(false);
          }
        })
        .catch();
    },
    [getClientByOrder, setValue, handlegetContact, getConsecutiveSLB, clearErrors, handlegetConFact, setDataClient]
  );

  //Funcion para consultar la informacion de las declaraciones del pedido
  const handlerChangeOrder = useCallback(
    async (value) => {
      setIsLoading(true);
      if (value !== "") {
        await getDataDim({ DOCNROXX: value })
          .unwrap()
          .then((response) => {
            const { data } = response;
            setDataDeclaration(data);
            setIsLoading(false);
          })
          .catch(() => {
            shootToast({
              variant: "error",
              text: "¡LO SENTIMOS MUCHO! Se presentaron problemas de conexión con GRM. Intente de nuevo en unos minutos",
              autoHide: false,
            });
            setIsLoading(false);
          });
        setValue("CLIIDXXX", "");
        setValue("DOCNROXX", "");
        handleGetClient(value);
        getDataErrors(value);
        dispatch(setMasiveValueUsd([valuesUsd]));
        dispatch(setMasiveValueTribute([valuesCif]));
        setValue("ANTTBDIX", "NO");
        setValue("ANTVLDIX", "NO");
        setValue("ANTTDIMX", "NO");
        setValue("ANTVDIMX", "NO");
        removeUsd();
        removeCif();
        appendCif(valuesCif);
        appendUsd(valuesUsd);
      }
      setOrder(value);
    },
    [
      getDataDim,
      getDataErrors,
      setValue,
      removeUsd,
      appendUsd,
      appendCif,
      removeCif,
      dispatch,
      handleGetClient,
      setOrder,
    ]
  );

  //Hook para setear los datos cuando viene el pedido
  useEffect(() => {
    if (data) {
      const { CLIIDXXX, DOCNROXX } = data;
      if (CLIIDXXX !== "") {
        setValue("CLIIDXXX", CLIIDXXX);
        clearErrors("CLIIDXXX");
        getDataClient(CLIIDXXX);
      }
      if (DOCNROXX !== "") {
        setValue("DOCNROXX", DOCNROXX);
        clearErrors("DOCNROXX");
        handlerChangeOrder(DOCNROXX);
      }
    }
  }, [data, handlerChangeOrder, setValue, getDataClient, clearErrors]);

  //Funcion para validar si un correo es valido
  const handleValidateEmail = (email) => {
    const emails = email.split(",");
    const regexp = getPatterEmail();
    let i = 0;
    emails.forEach((element) => {
      if (!regexp.test(element) && element !== "") {
        i++;
      }
    });
    if (i > 0) {
      setError("ANTEMAIL");
    } else {
      clearErrors("ANTEMAIL");
    }
  };

  const CLIIDXXX = useWatch({ control, name: "CLIIDXXX", defaultValue: "" });
  const ANTCREST = useWatch({ control, name: "ANTCREST", defaultValue: "" });

  //consulta del autocompletar
  const operations = async (value, cb, endpoint) => {
    if (CLIIDXXX !== "") {
      let filter = [];
      let cliId = CLIIDXXX.split(" - ")[0];
      filter["CLIIDXXX"] = cliId;
      filter["DOCNROXX"] = value;
      return await getAutocompleteWithParams({
        filter: filter,
        endpoint,
        MODURLXX: "Modimpxx",
        PRODURLX: "Anticipo",
      })
        .unwrap()
        .then((response) => {
          setResultsDo(response.data);
        })
        .catch((err) => {
          console.log("entro en el catch", err);
        })
        .finally(() => {
          cb();
        });
    } else {
      shootToast({
        text: "Debe seleccionar un Importador",
        variant: "info",
        autoHide: true,
        duration: 2000,
      });
      cb();
    }
  };

  return (
    <>
      <Box mb={8}>
        <Divider text="Datos de la Operación" color="accent-600" />
      </Box>
      <Box mb={8}>
        <Stack columnsVariant="form-2" gap={2}>
          <StackRow>
            <Label>Nit o Nombre del Importador</Label>
            {isClient ? (
              <Input
                {...register("CLIIDXXX", { required: data === null })}
                isInvalid={errors.CLIIDXXX}
                readOnly={isClient}
              />
            ) : (
              <Controller
                control={control}
                name={`CLIIDXXX`}
                rules={{ required: data === null }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    placeholder="Nit"
                    textLoading="Buscando..."
                    textNoResults="No se encontraron registros"
                    minLengthToSearch={2}
                    selected={value}
                    searchResults={results}
                    onChangeSearch={(value, cb) => handleSearch(value, cb)}
                    onItemClick={(context) => {
                      onChange(`${context.item.label}`);
                      handleChangeSelect(context);
                      clearErrors("CLIIDXXX");
                    }}
                    onResetSelected={(context) => {
                      onChange("");
                      handleChangeSelect(context, true);
                      setValue("ANTCREST", "");
                    }}
                    isInvalid={errors.CLIIDXXX}
                    readOnly={isCreating || data !== null || isClient}
                  />
                )}
              />
            )}
            {errors.CLIIDXXX && <FieldError>El campo Nit o Nombre del Importador no puede estar vacio</FieldError>}
          </StackRow>
          <StackRow>
            <Label>DO</Label>
            <Controller
              control={control}
              name={`DOCNROXX`}
              rules={{ required: data === null }}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  placeholder="DO"
                  textLoading="Buscando..."
                  textNoResults="No se encontraron registros"
                  minLengthToSearch={2}
                  selected={value}
                  searchResults={resultsDo}
                  onChangeSearch={(value, cb) => operations(value, cb, "getDoByClient")}
                  onItemClick={(context) => {
                    onChange(`${context.item.label}`);
                    handleChangeSelect(context);
                    clearErrors("DOCNROXX");
                    handlerChangeOrder(context.item.label);
                  }}
                  onResetSelected={(context) => {
                    onChange("");
                    handleChangeSelect(context, true);
                  }}
                  isInvalid={errors.DOCNROXX}
                  readOnly={isCreating || data !== null || isClient}
                />
              )}
            />
            {errors.DOCNROXX && <FieldError>El campo Pedido no puede estar vacio</FieldError>}
          </StackRow>
          <StackRow>
            <Label>Tasa</Label>
            <Input type="number" {...register("TRMTASXX")} value={trm} readOnly={isMod || isVisualize} />
            {errors.TRMTASXX && <FieldError>El campo Tasa no puede estar vacio</FieldError>}
          </StackRow>
          {(CLIIDXXX.split(" - ")[0] === 860002175 || CLIIDXXX.split(" - ")[0] === "860002175") && (
            <StackRow>
              <Label>Consecutivo SLB</Label>
              <Input {...register("ANTCOSBL")} readOnly />
            </StackRow>
          )}
          <StackRow>
            <Label>Responsabilidad Pago Tributos</Label>
            <Box border={1} spacing={2} rounded="md">
              <Flexbox gap={7} wrap>
                <Flexbox>
                  <Controller
                    control={control}
                    name="ANTCREST"
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <Checkbox
                        value="SIACO"
                        onChange={() => onChange("SIACO")}
                        checked={value === "SIACO"}
                        readOnly={isVisualize}
                      >
                        SIACO
                      </Checkbox>
                    )}
                  />
                </Flexbox>
                <Flexbox>
                  <Controller
                    control={control}
                    name="ANTCREST"
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <Checkbox
                        value="CLIENTE"
                        onChange={() => {
                          setMasiveValueUsd([valuesUsd]);
                          setMasiveValueTribute([valuesCif]);
                          onChange("CLIENTE");
                        }}
                        checked={value === "CLIENTE"}
                        readOnly={isVisualize}
                      >
                        CLIENTE
                      </Checkbox>
                    )}
                  />
                </Flexbox>
              </Flexbox>
            </Box>
            {errors.ANTCREST && <FieldError>El campo Tasa no puede estar vacio</FieldError>}
          </StackRow>
        </Stack>
      </Box>
      {isLoading ? (
        <BoxStatus variant="loading" text="Cargando información..." />
      ) : (
        <>
          {/* Solo se muestran las secciones si ANTCREST es "SIACO" */}
          {ANTCREST === "SIACO" && (
            <>
              {/* Seccion valores en aduana USD */}
              <Aduanusd
                dataDeclaration={dataDeclaration}
                isCreating={isCreating}
                fields={fieldsUsd}
                append={appendUsd}
                remove={removeUsd}
                isVisualize={isVisualize}
              />

              {/* Seccion valor tributos */}
              <Antribux
                dataDeclaration={dataDeclaration}
                isCreating={isCreating}
                fields={fieldsCif}
                append={appendCif}
                remove={removeCif}
                isVisualize={isVisualize}
              />
            </>
          )}

          {/* Seccion pagos a terceros */}
          <Pagthird isCreating={isCreating} client={CLIIDXXX.split(" - ")[0]} isVisualize={isVisualize} />
          {/* Seccion Ingresos propios */}
          <Ingrepro isCreating={isCreating} isVisualize={isVisualize} />
        </>
      )}
      <Box mb={8}>
        <Divider text="Envío" color="accent-600" />
      </Box>
      <Box mb={8}>
        <Box bg="neutral-200" border={1} borderColor="neutral-300" spacing={3} rounded="md" mb={8}>
          <Stack gap={2}>
            {fields.map((item, index) => (
              <Box key={item.id} spacingX={2} spacingTop={2} spacingBottom={4} rounded="md" bg="white">
                <Stack justifyItems="end">
                  <Button
                    type="button"
                    onClick={() => remove(index)}
                    variant="error-light"
                    icon="trash"
                    disabled={fields.length === 1 || isVisualize}
                    size="m"
                    onlyIcon
                    inactive={isCreating}
                  />
                </Stack>
                <Stack gap={4}>
                  <StackRow>
                    <Label>Enviar a</Label>
                    <Select
                      options={optionsContact}
                      {...register(`CONTACTX.${index}.CONTACID`)}
                      value={item.CONTACID}
                      onChangeValue={(context) => {
                        handleChangeContact(context, item, index);
                        clearErrors(`CONTACTX.${index}.CONTACID`);
                      }}
                      isInvalid={errors.CONTACTX && errors.CONTACTX[index] && errors.CONTACTX[index].CONTACID}
                      readOnly={isCreating || isVisualize}
                    />
                    {errors.CONTACTX && errors.CONTACTX[index] && errors.CONTACTX[index].CONTACID && (
                      <FieldError>El campo Enviar a no puede estar vacio</FieldError>
                    )}
                  </StackRow>
                </Stack>
              </Box>
            ))}
            <StackRow>
              <Button
                type="button"
                variant="secondary-light"
                icon="plus"
                disabled={optionsContact.length === 0}
                size="l"
                onClick={() => append(valuesCONTACTX)}
                inactive={isCreating || isVisualize}
              >
                Agregar otro
              </Button>
            </StackRow>
          </Stack>
        </Box>
        <Box mb={8}>
          <Stack gap={4}>
            <StackRow>
              <Label>Correos Adicionales (separados por coma)</Label>
              <Textarea
                {...register(`ANTEMAIL`)}
                onBlur={(e) => handleValidateEmail(e.target.value)}
                readOnly={isCreating || isVisualize}
              />
              {errors.ANTEMAIL && errors.ANTEMAIL && <FieldError>Correo invalido</FieldError>}
            </StackRow>
          </Stack>
        </Box>
        <Stack gap={4}>
          <StackRow>
            <Label>Observaciones</Label>
            <Textarea {...register(`ANTOBSXX`)} readOnly={isCreating || isVisualize} />
          </StackRow>
        </Stack>
      </Box>
      <Box mb={8} bg="accent-600" rounded="md" spacing={5}>
        <Stack columnsVariant="form-2">
          <StackRow>
            <Label weight="medium" color="white">
              Total del Anticipo
            </Label>
          </StackRow>
          <StackRow>
            <Label alignment="right" color="white">
              <input type="hidden" {...register(`ANTTOTAL`)} value={totalGeneral} />${" "}
              {convertCurrencyNumber(totalGeneral)}
            </Label>
          </StackRow>
        </Stack>
      </Box>
    </>
  );
};

export default Antcform;
