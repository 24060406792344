import { Text, View } from "@react-pdf/renderer";
import { styles } from "./stylesxx";

const Observsx = ({ data }) => {
  return (
    <View style={styles.containerTable}>
      <View style={styles.headerRow}>
        <Text style={styles.headerCell}>Observaciones</Text>
      </View>
      <View style={[styles.totalRow, { backgroundColor: "white" }]}>
        <Text style={styles.valueLabel}>
          Adjuntamos instructivo de Pago PSE, por favor una vez realizado el pago informar a su coordinador de cuenta
        </Text>
      </View>
      <View style={[styles.totalRow, { backgroundColor: "white" }]}>
        <Text style={styles.valueLabel}>{data.ANTOBSXX}</Text>
      </View>
    </View>
  );
};

export default Observsx;
