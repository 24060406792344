import React, { useState, useEffect } from "react";
import { Box, Select } from "codekit";
import {  TableScrollHeader, TableData } from "codekit";

const Facturas = ({ invoices, onSelectedRowsChange }) => {
  const [searchFilter, setSearchFilter] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [columnsTitle, setColumnsTitle] = useState([]);

  // Función para manejar el cambio del filtro de búsqueda
  const handleOnSearchFilterChange = (context) => {
    const option = context.option;
    const value = option.value;
    setSearchFilter(value);
  };

  // Función para manejar el cambio del valor del campo de búsqueda
  const handleSearchInputChange = (context) => {
    const { value } = context;
    setSearchValue(value);
  };

  // Filtrar las facturas según el filtro de búsqueda y el valor ingresado
  const filteredInvoices = invoices.filter((invoice) => {
    if (!searchFilter || searchValue.trim() === "") return true;
    const invoiceValue = invoice[searchFilter] ? String(invoice[searchFilter]) : "";
    return invoiceValue.includes(searchValue);
  });

  // Efecto para configurar las columnas inicialmente
  useEffect(() => {
    const generateColumns = () => {
      return [
        {
          id: "FACIDXXX",
          title: "No. Factura",
          isShow: true,
          isMobileShow: true,
          canToggleShow: false,
          sorted: false,
          orderBy: null,
          sortable: true,
          isTextBold: true,
        },
        {
          id: "CNSCOMXX",
          title: "Comprobante",
          isShow: true,
          isMobileShow: true,
          canToggleShow: false,
          sorted: true,
          orderBy: "desc",
        },
        {
          id: "CNSCOFEV",
          title: "Fecha de Vencimiento",
          isShow: true,
          isMobileShow: true,
          canToggleShow: false,
          sorted: true,
          orderBy: "desc",
          sortable: true,
        },
        {
          id: "VLRFACXX",
          title: "Valor",
          isShow: true,
          isMobileShow: true,
          canToggleShow: false,
          sorted: true,
          orderBy: "desc",
          sortable: true,
        },
      ];
    };
    setColumnsTitle(generateColumns());
  }, []);

  // Mapeo de los filtros para el campo de búsqueda
  const searchFiltersMapper = () => {
    return [
      { value: "CNSCOM2X", text: "No. Factura" },
      { value: "CNSCOMXX", text: "Comprobante" },
      { value: "CNSCOFEV", text: "Fecha de Vencimiento" },
    ];
  };

  // Función para manejar la selección de filas
  const handleSetCheckedRow = (context) => {
    const { rows } = context;
    onSelectedRowsChange(rows);
  };

  return (
    <Box spacing={1} spacingX={8}>

      {invoices.length === 0 ? (
        <Box textAlign="center" mt={2}>
          <p>No hay Facturas Disponibles para el proveedor.</p>
        </Box>
      ) : (
        <>
          <TableScrollHeader
            permissions={{
              consult: false,
              export: false,
              reports: false,
            }}
            selectFilter={
              <Select
                value={searchFilter}
                variant="no-border"
                onChangeValue={handleOnSearchFilterChange}
                options={searchFiltersMapper()}
              />
            }
            search={{
              placeholder: "Realiza una búsqueda",
              minLengthToSearch: 1,
              onSearch: handleSearchInputChange,
            }}
          />
          <TableData
            columns={columnsTitle}
            data={filteredInvoices.map((invoice) => ({
              FACIDXXX: invoice.CNSCOM2X,
              CNSCOMXX: `${invoice.IDCOMXXX} - ${invoice.COMPREXX} - ${invoice.CNSCOMXX}`,
              CNSCOFEV: invoice.CNSCOFEV,
              VLRFACXX: invoice.CONVLRCR,
            }))}
            isCheckedRow={true}
            handleCheckedRow={handleSetCheckedRow}
          />
          {filteredInvoices.length === 0 && (
            <Box textAlign="center" mt={2}>
              <p>No se encontraron resultados para los criterios de búsqueda.</p>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default Facturas;
