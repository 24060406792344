import { Text, View } from "@react-pdf/renderer";
import { convertCurrencyNumber } from "../../utils/function";
import { styles } from "./stylesxx";

const Thirdsxx = ({ data }) => {
  const { PAGOSTER, INGREPRO, ANTEVLRX, ANTPVLRX } = data;
  const total = parseFloat(ANTEVLRX) + parseFloat(ANTPVLRX);
  const mergeData = PAGOSTER.concat(INGREPRO);
  return (
    <View style={styles.containerTable}>
      <View style={styles.headerRow}>
        <Text style={styles.headerCell}>Rubros ( COP )</Text>
      </View>
      {mergeData.map((item, index) => {
        let color = "white";
        if (index % 2 !== 0) {
          color = "#EEF4F9";
        }
        return (
          <View key={`third-${index}`} style={[styles.headerRow, { backgroundColor: color }]}>
            <Text style={styles.valueLabel}>{item.CONDESXX ? item.CONDESXX : item.CONCOBDE ?? ""}</Text>
            <Text style={styles.headerCell}>
              $ {convertCurrencyNumber(item.ANTEVLRX ? item.ANTEVLRX : item.ANTPVLRX ?? 0)}
            </Text>
          </View>
        );
      })}
      {/* {INGREPRO.map((item, index) => (
        <View key={`owm-${index}`} style={[styles.headerRow, { backgroundColor: "white" }]}>
          <Text style={styles.valueLabel}>{item.CONCOBDE}</Text>
          <Text style={styles.headerCell}>$ {convertCurrencyNumber(item.ANTPVLRX)}</Text>
        </View>
      ))} */}
      <View style={styles.totalRow}>
        <Text style={styles.totalText}>TOTAL</Text>
        <Text style={[styles.totalText, { textAlign: "right" }]}>$ {convertCurrencyNumber(total)}</Text>
      </View>
    </View>
  );
};

export default Thirdsxx;
