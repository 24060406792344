//Funcion que retorna los valores en USD sumados de todas las declaraciones
export const sumDimUsd = (dataDim) => {
  let LIMVLRXX = 0;
  let LIMFLEXX = 0;
  let LIMSEGXX = 0;
  let LIMOTRXX = 0;
  let LIMAJUXX = 0;
  let ANTTOTAL = 0;
  dataDim.forEach((dim) => {
    LIMVLRXX += parseFloat(dim.LIMVLRXX);
    LIMFLEXX += parseFloat(dim.LIMFLEXX);
    LIMSEGXX += parseFloat(dim.LIMSEGXX);
    LIMOTRXX += parseFloat(dim.LIMOTRXX);
    LIMAJUXX += parseFloat(dim.LIMAJUXX);
  });
  ANTTOTAL += LIMVLRXX + LIMFLEXX + LIMSEGXX + LIMOTRXX + LIMAJUXX;
  return {
    LIMVLRXX,
    LIMFLEXX,
    LIMSEGXX,
    LIMOTRXX,
    LIMAJUXX,
    ANTTOTAL,
  };
};

//Funcion que retorna los valores sumados de todas las declaraciones
export const sumDimCif = (dataDim) => {
  let LIMGRAXX = 0;
  let ANTTOTAL = 0;
  let LIMSUBTX = 0;
  let SUBSALTP = 0;
  let SUBDCOTP = 0;
  let SUBANTTP = 0;
  let SUBSANTP = 0;
  let SUBRESTP = 0;
  dataDim.forEach((dim) => {
    LIMGRAXX += parseFloat(dim.LIMGRAXX);
    LIMSUBTX += parseFloat(dim.LIMSUBTX);
    SUBSALTP += parseFloat(dim.SUBSALTP);
    SUBDCOTP += parseFloat(dim.SUBDCOTP);
    SUBANTTP += parseFloat(dim.SUBANTTP);
    SUBSANTP += parseFloat(dim.SUBSANTP);
    SUBRESTP += parseFloat(dim.SUBRESTP);
  });
  ANTTOTAL += LIMGRAXX + LIMSUBTX + SUBSALTP + SUBDCOTP + SUBANTTP + SUBSANTP + SUBRESTP;
  return {
    LIMGRAXX,
    ANTTOTAL,
    LIMSUBTX,
    SUBSALTP,
    SUBDCOTP,
    SUBANTTP,
    SUBSANTP,
    SUBRESTP,
  };
};
