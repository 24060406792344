/**
 * Constuctor de graficas
 */

import { Graphsel } from "./Graphsel";

export function Bugraphs({ graphs }) {
  const getGraphKeyName = (graphName) => {
    if (graphName) {
      return graphName.toLowerCase().split(" ").join("-");
    }
    return "";
  };

  const converFristUppercase = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  return (
    <>
      {graphs.map((graph, keyGraph) => {
        return (
          <Graphsel
            key={keyGraph}
            graphName={graph?.GRANOMXX}
            graphComponent={converFristUppercase(graph?.GRACOMXX ?? "")}
            MODIDXXX={107}
            PROIDXXX={101}
            graphKeyName={getGraphKeyName(graph?.GRANOMXX)}
          />
        );
      })}
    </>
  );
}
